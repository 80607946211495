import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '../../../models/error-message.interface';

export const Activation = createAction('[Activation Page] Activation');

export const ActivationComplete = createAction(
  '[Activation Page] Activation Complete'
);

export const ActivationSuccess = createAction(
  '[Activation API] Activation Success'
);

export const ActivationFailure = createAction(
  '[Activation API] Activation Failure',
  props<{ payload: ErrorMessage }>()
);
