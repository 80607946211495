<footer id="page-footer" class="flex flex-row justify-center">
  <div class="flex flex-row w-11/12 lg:w-9/12 justify-between">

    <div class="links flex flex-col md:flex-row justify-between md:w-4/5" *ngIf="!(onMobile$ | async) || showFooterOnMobile">
      <small
        ><a
          href="https://www.portbase.com/privacyverklaring/"
          target="_blank"
          >{{ 'home.privacy' | translate }}</a
        ></small
      >

      <small><a routerLink="/docs/cookies">Cookies </a></small>
    </div>

    <span class="powered_by" *ngIf="!showFooterOnMobile">
      <img src="assets/img/logo.svg" />
    </span>

    <span class="mobile-show-footer" (click)="showFooterOnMobile = !showFooterOnMobile" *ngIf="onMobile$ | async">
      <i *ngIf="!showFooterOnMobile" class="fa-light fa-chevron-up fa-xl"></i>
      <i *ngIf="showFooterOnMobile" class="fa-light fa-chevron-down fa-xl"></i>
    </span>
  </div>
</footer>
