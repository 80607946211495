import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RXJS
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, take } from 'rxjs/operators';

// NGRX
import { select, Store } from '@ngrx/store';
import { environment } from 'projects/iam/src/environments/environment';
import { portalQuery } from '../../store/selectors/portal.selectors';
import { UserInfo } from '../../models/user/userinfo.interface';

// Models

@Injectable({
  providedIn: 'root',
})
export abstract class IamRestService {
  protected baseUrl = environment.apiEndpoints.resourcesApiUrl;

  protected userId$ = this.store.pipe(
    select(portalQuery.getUserProfile),
    map((userInfo: UserInfo) => (userInfo && userInfo.sub ? userInfo.sub : '')),
    distinctUntilChanged()
  );

  protected organizationId$ = this.store.pipe(select(portalQuery.getCurrentOrganizationId), distinctUntilChanged());

  private urlReplacements = [
    {
      tag: ':userId',
      replacement: this.userId$,
    },
    {
      tag: ':organizationId',
      replacement: this.organizationId$,
    },
  ];

  constructor(private http: HttpClient, private store: Store) {}

  protected formatUrl(url: string): Observable<string> {
    const obs$ = this.urlReplacements.map(item => item.replacement);
    return combineLatest(obs$).pipe(
      take(1),
      map(params => {
        for (const [index, item] of this.urlReplacements.entries()) {
          const param = params[index];
          url = url.replace(item.tag, param);
        }

        return url;
      })
    );
  }

  get<T>(url: string): Observable<T> {
    return this.formatUrl(url)
      .pipe(mergeMap(url => this.http.get<T>(url, { observe: 'response' })))
      .pipe(map(response => response.body));
  }

  post<T>(url: string, body: unknown): Observable<T> {
    return this.formatUrl(url)
      .pipe(mergeMap(url => this.http.post<T>(url, body, { observe: 'response' })))
      .pipe(map(response => response.body));
  }

  put<T>(url: string, body: unknown): Observable<T> {
    return this.formatUrl(url)
      .pipe(mergeMap(url => this.http.put<T>(url, body, { observe: 'response' })))
      .pipe(map(response => response.body));
  }

  delete<T>(url: string): Observable<T> {
    return this.formatUrl(url)
      .pipe(mergeMap(url => this.http.delete<T>(url, { observe: 'response' })))
      .pipe(map(response => response.body));
  }
}
