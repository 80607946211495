import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RXJS
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';

// Models
import { PortalPages } from '../../models/portal/portal-pages';

// Services
import { IamRestService } from './iam-rest.service';
import { PortalInfo, PortalLocales } from '../../models/portal/portal-info.interface';

@Injectable({
  providedIn: 'root',
})
export class PortalInfoService extends IamRestService {
  constructor(private _http: HttpClient, private _store: Store) {
    super(_http, _store);
  }

  getPortalInfo(
    userId: string,
    portalPage: PortalPages
  ): Observable<PortalInfo> {
    const url = `${this.baseUrl}/portalinfo/${userId}/${portalPage}`;
    return this.get(url);
  }

  changeLanguage(locale: PortalLocales): Observable<unknown> {
    const url = `${this.baseUrl}/users/:userId`;
    return this.userId$.pipe(
      switchMap(id =>
        this.put<unknown>(url, {
          id,
          locale,
        })
      )
    );
  }
}
