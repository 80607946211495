//NGRX
import { Action, createReducer, on } from '@ngrx/store';
import { DialogClosed, OpenDialog } from './../actions/dialog.action';

export const DIALOG_FEATURE_KEY = 'dialog';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface DialogState {
  isOpened: boolean;
  dialogType: string | undefined;
}

export const initialSate: DialogState = {
  isOpened: false,
  dialogType: undefined,
};

const dialogReducers = createReducer(
  initialSate,
  on(OpenDialog, (state, { dialogType }) => ({
    ...state,
    isOpened: true,
    dialogType,
  })),
  on(DialogClosed, state => ({
    ...state,
    isOpened: false,
    dialogType: undefined,
  }))
);

export function dialogReducer(
  state: DialogState | undefined,
  action: Action
): DialogState {
  return dialogReducers(state, action);
}
