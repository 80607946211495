import { Action, createReducer, on } from '@ngrx/store';
import { ActivationSuccess } from '../../auth/store/actions/activation.actions';

export interface ActivationState {
  isActivated: boolean;
}

export const initialState: ActivationState = {
  isActivated: false,
};

const activationReducers = createReducer(
  initialState,
  on(ActivationSuccess, state => ({
    ...state,
    isActivated: true,
  }))
);

export function reducer(
  state: ActivationState | undefined,
  action: Action
): ActivationState {
  return activationReducers(state, action);
}

export const selectIsActivated = (state: ActivationState): boolean =>
  state.isActivated;
