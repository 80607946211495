<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'services'">
    <ngx-skeleton-loader
      [count]="1"
      [theme]="{
        'border-radius': '5px',
        height: '40px'
      }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
      tabindex="100"
      [count]="count"
      [theme]="{
        height: '150px',
        width: '190px',
        'border-radius': '5px',
        border: '0px solid white',
        margin: '16px'
      }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
      tabindex="100"
      [count]="1"
      [theme]="{
        height: '40px',
        border: '0px solid white',
        margin: '8px 0 0 0'
      }"
    ></ngx-skeleton-loader>
  </ng-container>
  <ng-container *ngSwitchCase="'table'">
    <ngx-skeleton-loader
      [count]="1"
      [theme]="{
        'border-radius': '5px',
        height: '40px',
        'margin-top': '16px'
      }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
      [count]="count"
      [theme]="{
        'border-radius': '5px',
        height: '48px',
        margin: '0px'
      }"
    ></ngx-skeleton-loader>
  </ng-container>
  <ng-container *ngSwitchCase="'title'">
    <ngx-skeleton-loader
      tabindex="100"
      [count]="count"
      [theme]="{
        height: '35px',
        border: '0px solid white'
      }"
    ></ngx-skeleton-loader>
  </ng-container>
  <ng-container *ngSwitchCase="'title-text'">
    <ngx-skeleton-loader
      [count]="1"
      [theme]="{
        'border-radius': '5px',
        height: '55px',
        'margin-bottom': '0px'
      }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
      [count]="1"
      [theme]="{
        'border-radius': '5px',
        height: '40px'
      }"
    ></ngx-skeleton-loader>
  </ng-container>
  <ng-container *ngSwitchCase="'button'">
    <ngx-skeleton-loader
      tabindex="100"
      [count]="count"
      [theme]="{
        height: '40px',
        width: '120px',
        border: '0px solid white',
        'margin-left': count > 1 ? '12px' : '0px'
      }"
    ></ngx-skeleton-loader>
  </ng-container>
  <ng-container *ngSwitchCase="'field'">
    <ngx-skeleton-loader
      [count]="count"
      [theme]="{
        'border-radius': '5px',
        height: '50px',
        margin: '8px 0 0 0'
      }"
    ></ngx-skeleton-loader>
  </ng-container>
  <ng-container *ngSwitchCase="'small-box'">
    <ngx-skeleton-loader
      tabindex="100"
      [count]="count"
      [theme]="{
        height: '100px',
        width: '100px',
        'border-radius': '5px',
        border: '0px solid white'
      }"
    ></ngx-skeleton-loader>
  </ng-container>
  <ng-container *ngSwitchCase="'box'">
    <ngx-skeleton-loader
      tabindex="100"
      [count]="count"
      [theme]="{
        height: '200px',
        'border-radius': '5px',
        border: '0px solid white'
      }"
    ></ngx-skeleton-loader>
  </ng-container>
  <ng-container *ngSwitchCase="'column'">
    <ngx-skeleton-loader
      tabindex="100"
      [count]="count"
      [theme]="{
        height: '400px',
        width: '45%',
        'border-radius': '5px',
        border: '0px solid white',
        'margin-left': '16px'
      }"
    ></ngx-skeleton-loader>
  </ng-container>
  <ng-container *ngSwitchCase="'selector'">
    <ngx-skeleton-loader
      [count]="count"
      [theme]="{
        'border-radius': '5px',
        height: '40px',
        width: '100px',
        'margin-bottom': '0px',
        'padding-left': '12px'
      }"
    ></ngx-skeleton-loader>
  </ng-container>
  <ng-container *ngSwitchCase="'name'">
    <ngx-skeleton-loader
      [count]="1"
      [theme]="{
        'border-radius': '5px',
        height: '40px',
        width: '200px',
        'margin-bottom': '0px'
      }"
    ></ngx-skeleton-loader>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ngx-skeleton-loader
      [count]="count"
      [theme]="{
        'border-radius': '5px',
        height: '50px'
      }"
    ></ngx-skeleton-loader
  ></ng-container>
</ng-container>
