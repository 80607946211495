import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MDModule } from './md.module';
import { ButtonModule } from 'primeng/button';

import { DialogComponent } from './components/dialog-component/dialog.component';

//NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  DIALOG_FEATURE_KEY,
  dialogReducer,
} from './store/reducers/dialog.reducer';
import { DialogEffects } from './store/effects/dialog.effects';
import { DialogService } from './services/dialog.service';

@NgModule({
  imports: [
    CommonModule,
    MDModule,
    ButtonModule,
    StoreModule.forFeature(DIALOG_FEATURE_KEY, dialogReducer),
    EffectsModule.forFeature([DialogEffects]),
  ],
  exports: [DialogComponent],
  declarations: [DialogComponent],
  providers: [DialogService],
})
export class PortbaseDialogModule {}
