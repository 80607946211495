import { Component, Input } from '@angular/core';

@Component({
  selector: 'iam-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  providers: [],
})
export class SkeletonLoaderComponent {
  @Input() type:
    | 'field'
    | 'services'
    | 'table'
    | 'title'
    | 'title-text'
    | 'button'
    | 'box'
    | 'small-box'
    | 'column'
    | 'selector'
    | 'name';
  @Input() count = 1;
}
