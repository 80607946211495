import { Action, createReducer, on } from '@ngrx/store';
import * as SnackbarActions from './snackbar.actions';

export const SNACKBAR_FEATURE_KEY = 'snackbar';

export interface SnackbarState {
  isVisible: boolean;
}

export const initialState: SnackbarState = {
  isVisible: false,
};

const snackbarReducer = createReducer(
  initialState,
  on(SnackbarActions.ShowSnackbar, (state, {}) => ({
    ...state,
    isVisible: true,
  })),
  on(SnackbarActions.SnackbarClosed, (state, {}) => ({
    ...state,
    isVisible: false,
  }))
);

export function SnackbarReducer(
  state: SnackbarState | undefined,
  action: Action
): SnackbarState {
  return snackbarReducer(state, action);
}
