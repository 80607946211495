import { createAction, props } from '@ngrx/store';

export const OpenDialog = createAction(
  '[Modal] Open Dialog',
  props<{ dialogType: string }>()
);

export const DialogClosed = createAction('[Modal] Dialog Closed');

export const CloseDialog = createAction('[Modal] Close Dialog');
