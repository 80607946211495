import { Injectable } from '@angular/core';
import { Router, RouterState } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { LANGUAGES } from '../../../constants/languages';
import { AuthenticationService } from '../../../core/services/authentication.service';
import {
  ActivationComplete,
  ActivationFailure,
  ActivationSuccess,
} from '../actions/activation.actions';
import { selectQueryParameters } from '../../../store/selectors/router.selectors';

interface StateToken {
  state_token: string;
  locale: string;
}

@Injectable()
export class ActivationEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private router: Router,
    private store: Store<RouterState>
  ) {}

  activationComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivationComplete),
      withLatestFrom(this.store.select(selectQueryParameters)),
      switchMap(data => {
        // @ts-ignore
        const queryParams = data[1];
        // @ts-ignore
        const activationToken = queryParams['activation_token'];

        return this.authService.postActivation(activationToken).pipe(
          // @ts-ignore
          filter(stateToken => Boolean(stateToken)),
          map((stateToken: StateToken) => {
            this.authService.redirectLogin(
              stateToken.state_token,
              // @ts-ignore
              LANGUAGES.find(lang => lang['locale'] === stateToken.locale).okta
            );
            return ActivationSuccess();
          }),
          catchError(error => of(ActivationFailure(error)))
        );
      })
    )
  );

  activationErrorRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActivationFailure),
        tap(() => this.router.navigate(['/']))
      ),
    { dispatch: false }
  );
}
