import { PortalPages } from '../../models/portal/portal-pages';

export const convertRouteToPortalPage = (url: string): PortalPages => {
  switch (true) {
    case url.includes(PortalPages.PROFILE):
      return PortalPages.PROFILE;
    case url.includes(PortalPages.USERMANAGEMENT):
      return PortalPages.USERMANAGEMENT;
    case url.includes(PortalPages.ORGANIZATIONS):
      return PortalPages.ORGANIZATIONS;
    case url.includes(PortalPages.OPEN_REQUESTS):
      return PortalPages.OPEN_REQUESTS;
    case url.includes(PortalPages.SERVICEMANAGEMENT):
      return PortalPages.SERVICEMANAGEMENT;
    default:
      return PortalPages.HOME;
  }
};
