import { APP_INITIALIZER, ErrorHandler, Inject, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MDModule } from './md.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';

import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './store/reducers/state';
import { effects } from './store/effects/effects';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';

import { CustomLoader } from './core/translation/custom-loader';

import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import localeDe from '@angular/common/locales/de';
import { APP_PROVIDER } from './app.interceptor';
import { SNACKBAR_MESSAGE_PROVIDER } from './snackbar-message.interceptor';
import { environment } from '../environments/environment';
import { RumErrorHandler } from './libs/shared/error-handling/rum-error-handler';
import { DirectivesModule } from './libs/directives/src/lib/directives.module';
import { PortbaseSnackbarModule } from './libs/portbase-snackbar-components/src/lib/portbase-snackbar.module';
import { checkOpenRequest, initApplication } from './app.initializer';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RoleModule } from 'projects/iam-manager/src/app/core/guards/role.module';
import { BeamerService } from './core/services/beamer.service';


registerLocaleData(localeNl, 'nl');
registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [AppComponent],
  providers: [
    APP_PROVIDER,
    SNACKBAR_MESSAGE_PROVIDER,
    {
      provide: ErrorHandler,
      useClass: RumErrorHandler,
    },
    {
      provide: APP_INITIALIZER, 
      useFactory: checkOpenRequest,
      multi: true,
      deps: [[new Inject(Store)], [new Inject(Router)]]
    },
    {
      provide: APP_INITIALIZER, 
      useFactory: initApplication,
      multi: true,
      deps: [[new Inject(Store)], [new Inject(BeamerService)]]
    }
  ],
  imports: [
    AppRoutingModule,
    AuthModule,
    CoreModule,
    BrowserAnimationsModule,
    DirectivesModule,
    MDModule,
    RoleModule,
    FontAwesomeModule,
    PortbaseSnackbarModule.forRoot({
      showIcon: true,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration: 15000,
    }),
    ToastrModule.forRoot({
      // to see configure options here https://ngx-toastr.netlify.com/
      timeOut: 10000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      countDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        deps: [],
      },
    }),
    HttpClientModule,
    StoreModule.forRoot(reducers),
    environment.runReduxDevTools ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
  ],
  exports: [TranslateModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
