import { ClassProvider, Injectable } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ShowSnackbar } from './libs/portbase-snackbar-components/src/lib/store/snackbar.actions';

@Injectable()
export class SnackBarMessageInterceptor implements HttpInterceptor {
  constructor(
    private toasterService: ToastrService,
    private translateService: TranslateService
  ) {}

  /* eslint-disable @typescript-eslint/no-explicit-any */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /* eslint-enable @typescript-eslint/no-explicit-any */
    if (request.method === 'GET') {
      return next.handle(request).pipe(
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse) {
            const statusCodeDescription = this.createErrorMsgFromBackend(err);
            // this.showErrorToaster(statusCodeDescription);

            ShowSnackbar({
              message: this.translateService.stream(
                'YES' + statusCodeDescription
              ),
              level: 'warning',
            });
          }
          return throwError(err);
        })
      );
    }

    return next.handle(request);
  }

  private createErrorMsgFromBackend(err: HttpErrorResponse): string {
    // Get the translationKey from the backend, and if not available fallback to the generic one for this status code
    const translationKey =
      (err.error ? err.error.error_code : null) ||
      `errorTexts.httpStatusCodes.${err.status}`;

    let statusCodeDescription = this.translateService.instant(translationKey);

    // If the key could not be translated, fallback to the generic one
    if (statusCodeDescription === translationKey) {
      statusCodeDescription = this.translateService.instant(
        'errorTexts.general.unknown'
      );
    }

    return statusCodeDescription;
  }

  private showErrorToaster(statusCodeDescription: string): void {
    this.toasterService.error(
      statusCodeDescription,
      this.translateService.instant('errorTexts.general.title')
    );
  }
}
export const SNACKBAR_MESSAGE_PROVIDER: ClassProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: SnackBarMessageInterceptor,
  multi: true,
};
