<ng-container *ngIf="!(showLoader$ | async); else loading">
  <div *ngIf="portalInfo$ | async as portalInfo" class="ml-5 mr-6 leading-3 pr-4 mt-3 flex flex-col">
    <span class="user-details" *ngIf="portalInfo.profile?.name" data-test="user-details">
      {{ portalInfo.profile.name }}
    </span>
    <span class="is-admin text-tiny sm:visible invisible" 
      *ngIf="portalInfo.currentOrganization?.member_of === userGroups.ADMINS" data-test="admin-label">
      {{ 'groupStatus.admins' | translate }}
    </span>

    <span class="is-usermanager text-tiny sm:visible invisible" *ngIf="
        portalInfo.currentOrganization?.member_of === userGroups.USERMANAGERS
      " data-test="usermanager-label">
      {{ 'groupStatus.usermanagers' | translate }}
    </span>

    <span class="is-member text-tiny sm:visible invisible" *ngIf="
      portalInfo.currentOrganization?.member_of === userGroups.MEMBERS
    " data-test="members-label">
      {{ 'groupStatus.members' | translate }}
    </span>
  </div>
</ng-container>
<ng-template #loading>
  <iam-skeleton-loader type="name"></iam-skeleton-loader>
</ng-template>
