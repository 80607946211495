import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

// RXJS
import { startWith, takeUntil } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';

// Models
// NGRX
import { portalQuery } from '../../../../../store/selectors/portal.selectors';
import { State } from '../../../../../store/reducers/state';
import { DefaultUserGroups } from '../../../../../models/default-user-groups.interface';
import { DestroyService } from '../../../../shared/services/destroy.service';

@Directive({
  selector: '[ifInRole]',
  providers: [DestroyService],
})
export class IfInRoleDirective implements OnInit {
  @Input() ifInRole: string | string[] | undefined;
  private hasView = false;

  constructor(
    private unsubscribe$: DestroyService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<IfInRoleDirective>,
    private store: Store<State>
  ) {}

  ngOnInit(): void {
    this.store
      .select(portalQuery.getCurrentOrganizationRole)
      .pipe(startWith([]), takeUntil(this.unsubscribe$))
      .subscribe(userRoles => this.checkUserRoles(userRoles || []));
  }

  checkUserRoles(userRoles: string[]): void {
    // Make sure this is an array, since the input can either be a string or a string[]
    const permittedRoles: string[] = [].concat(...[this.ifInRole]);

    const hasPermittedRole = userRoles.some(
      role => permittedRoles.indexOf(role) >= 0
    );
    const isMembers = userRoles.indexOf(DefaultUserGroups.MEMBERS) !== -1;
    const hasAccess = hasPermittedRole && isMembers;

    // If the user does not have permission then clear the element, else create the view
    if ((hasAccess || !this.ifInRole) && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!hasAccess && !!this.ifInRole && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
