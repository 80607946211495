import {
  PortalInfo,
  PortalLocales,
} from '../../models/portal/portal-info.interface';
import { createAction, props } from '@ngrx/store';
import { PortalPages } from '../../models/portal/portal-pages';
import { RequiredField } from '../../models/required-field.interface';
import { ErrorMessage } from '../../models/error-message.interface';

export const SelectPortalOrganization = createAction(
  '[Portal] Select Organization',
  props<{
    organizationId: string;
    portalPage: PortalPages;
    suppressBackendUpdate?: boolean;
  }>()
);

export const SelectPortalOrganizationSuccess = createAction(
  '[Portal] Select Organization Success',
  props<{ organizationId: string }>()
);

export const SetPortalCurrentOrganizationRole = createAction(
  '[Portal] Set Current Organization Role',
  props<{ roles: string[] }>()
);

export const PortalInfoLoad = createAction(
  '[Portal] Portal Info Load',
  props<{ userId: string; portalPage: PortalPages }>()
);

export const PortalInfoReload = createAction('[Portal] Portal Info ReLoad');

export const PortalInfoLoaded = createAction(
  '[Portal] Portal info loaded',
  props<{ portalInfo: PortalInfo; portalPage: PortalPages }>()
);

export const PortalInfoError = createAction(
  '[Portal] Portal Info error',
  props<{ error: ErrorMessage }>()
);

export const UpdatePortalInfo = createAction(
  '[Portal] Update portal info',
  props<{ profile: Partial<PortalInfo> }>()
);

export const ChangePortalLanguage = createAction(
  '[Portal] Change language',
  props<{ locale: PortalLocales }>()
);

export const UpdatePortalRequiredFields = createAction(
  '[Portal] Update required missing fields',
  props<{ missingFields: RequiredField[] }>()
);

export const ShowedRequiredFieldsDialog = createAction(
  '[Portal] Showed requiredfields dialog'
);
