import { createSelector } from '@ngrx/store';
import { getRouterState } from '../reducers/state';

export const selectQueryParameters = createSelector(getRouterState, router =>
  router ? router.state.root.queryParams : null
);

export const getRouterUrl = createSelector(getRouterState, router =>
  router ? router.state.url : null
);
