import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'getTooltipTranslationContent' })
export class GetTooltipTranslationPipe implements PipeTransform {
  transform(dataList: unknown[], code: unknown): string {
    const item = dataList.find(
      list => JSON.stringify(list['code']) === JSON.stringify(code)
    );
    return item ? (item['tooltip'] ? item['tooltip'] : '') : '';
  }
}
