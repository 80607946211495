export const sharedTranslations = {
  organizationTypesDescription: {
    BARGE_OPERATOR: '',
    CARGADOOR_SHIPAGENT:
      'The shipbroker / ship agent is traditionally the representative of one or more shipping companies in a port. The tasks of the shipbroker / ship agent are ship and crew matters on one hand and acting as a representative of cargo stakeholders on the other hand.',
    CUSTOMS: '',
    EMPTY_DEPOT: '',
    EXPORTER: '',
    FOOD_HEALTH_SERVICE: '',
    FORWARDER: '',
    IMPORTER: '',
    INSPECTION_STATION: '',
    IT_SERVICE_PROVIDER:
      'An IT service provider provides the delivery and management of network-based services, applications to businesses',
    PORT_AUTHORITY: '',
    RAIL_HAULIER: '',
    RAIL_OPERATOR: '',
    RAILWAY_AUTHORITY: '',
    ROAD_HAULIER: '',
    SHIPPING_COMPANY: '',
    SKIPPER: '',
    TERMINAL: '',
  },
  organizationRolesDescription: {
    EMPLOYEE: 'Employee of an organization',
    FINANCIAL_DECLARANT:
      'A financial declarant is responsible for the formalities and declaration of one or more port logistics processes.',
    IAM_ADMIN: '',
    ID_CHECKER: '',
    OPERATOR_SHIPPING_CLERK:
      'Responsible for the formal shipping and freight documents.',
    SCHEME_ADMIN: '',
    SD_EMPLOYEE: '',
    SD_STC: '',
  },
};
