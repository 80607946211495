import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

// RXJS
import { take } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';

import { isObservable, Observable, of } from 'rxjs';
import { SnackbarState } from '../../store/snackbar.reducer';
import { SnackbarMessage } from '../../models/snackbar-message.model';
import { SnackbarClosed } from '../../store/snackbar.actions';

@Component({
  selector: 'iam-error-snackbar',
  templateUrl: './error-snackbar.component.html',
  styleUrls: ['./error-snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorSnackbarComponent {
  constructor(
    private store: Store<SnackbarState>,
    public snackBarRef: MatSnackBarRef<ErrorSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarMessage
  ) {
    this.snackBarRef
      .afterDismissed()
      .pipe(take(1))
      .subscribe(() => {
        this.store.dispatch(SnackbarClosed());
      });
  }

  close(): void {
    this.snackBarRef.dismiss();
  }

  generateTextObservable(
    message: Observable<string> | string
  ): Observable<string> {
    return isObservable(message) ? message : of(message);
  }
}
