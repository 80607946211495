import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Data,
  Router,
} from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { AuthenticationService } from '../services/authentication.service';
import { portalQuery } from '../../store/selectors/portal.selectors';
import { PortalState } from '../../store/reducers/portal.reducer';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  currentUserRoles$ = this.store.select(portalQuery.getCurrentOrganizationRole);

  constructor(
    private authService: AuthenticationService,
    private store: Store<PortalState>,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkStoreAuthentication().pipe(
      mergeMap(storeAuth => {
        if (storeAuth) {
          return of(true);
        }

        return this.checkApiAuthentication();
      }),
      mergeMap(storeOrApiAuth => {
        if (!storeOrApiAuth) {
          this.router.navigate(['/']);
          return of(false);
        }

        // Only check the user roles when the route calls for it
        if (route.data && 'allowedFor' in route.data) {
          return this.checkCurrentUserRoles(route.data);
        }
        return of(true);
      }),
      map(userHasAccess => {
        if (!userHasAccess) {
          this.router.navigate(['/']);
          return false;
        }

        return true;
      }),
      catchError(err => {
        this.router.navigate(['/']);
        return throwError(err);
      })
    );
  }

  checkCurrentUserRoles(
    routeData: { allowedFor: string[] } | Data
  ): Observable<boolean> {
    return this.store.select(portalQuery.getCurrentOrganizationRole).pipe(
      map(currentUserRoles => {
        if (!routeData || !routeData.allowedFor) {
          return true;
        }

        const hasAccess = currentUserRoles.some(
          role => routeData.allowedFor.indexOf(role) >= 0
        );

        return hasAccess;
      })
    );
  }

  checkStoreAuthentication(): Observable<boolean> {
    return this.store.select(portalQuery.loggedInToPortal);
  }

  checkApiAuthentication(): Observable<boolean> {
    return this.authService.verifyToken().pipe(
      mergeMap(() =>
        this.store.pipe(
          select(portalQuery.loadedPortal),
          filter(res => !!res),
          map(() => true)
        )
      ),
      catchError(() => of(false))
    );
  }
}
