import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import {
  LoginFailure,
  LoginSuccess,
  Logout,
  LogoutComplete,
} from '../../auth/store/actions/auth.actions';
import {
  PortalChangeRequest,
  PortalCurrentOrganization,
  PortalLocales,
  PortalNotifications,
  PortalOrganization,
  QuarterlyReviewStatus,
} from '../../models/portal/portal-info.interface';
import {
  PortalInfoError,
  PortalInfoLoad,
  PortalInfoLoaded,
  SelectPortalOrganization,
  SelectPortalOrganizationSuccess,
  SetPortalCurrentOrganizationRole,
  ShowedRequiredFieldsDialog,
  UpdatePortalInfo,
  UpdatePortalRequiredFields,
} from '../actions/portal.actions';
import { ServiceGroup } from '../../models/service.interface';
import { RequiredField } from '../../models/required-field.interface';
import {
  CancelActivationCodeRequestsSuccess,
  LoadChangeRequestsSuccess,
} from '../actions/change-requests.actions';
import { UserBusinessProfile } from '../../models/user/user-business-profile.interface';

export interface PortalState {
  businessInformation: UserBusinessProfile;
  currentOrganization: PortalCurrentOrganization;
  organizations: Array<PortalOrganization>;
  services: Array<ServiceGroup>;
  marketplace: Array<ServiceGroup>;
  changeRequest: PortalChangeRequest;
  notifications: PortalNotifications;
  profile: {
    name?: string;
    sub?: string;
    locale?: PortalLocales;
    ext_identified?: boolean;
    stc?: boolean;
    email_verified?: boolean;
    proposed_email?: string;
    current_quarterly_review_status:QuarterlyReviewStatus | null;
  };
  loading: boolean;
  loaded: boolean;
  loggedIn: boolean;
  loggingOut: boolean;
  missingFields: RequiredField[];
  showedMissingFielsdDialog: boolean;
  sub?: string;
  features: { [key: string]: boolean };
}

export const initialState: PortalState = {
  // @ts-ignore
  businessInformation: null,
  currentOrganization: {
    roles: [],
  },
  organizations: [],
  services: [],
  marketplace: [],
  // @ts-ignore
  changeRequest: null,
  notifications: {
    pending_users: 0,
    change_business_info_request: 0,
  },
  // @ts-ignore
  profile: null,
  loading: false,
  loaded: false,
  loggedIn: false,
  loggingOut: false,
  missingFields: [],
  showedMissingFielsdDialog: false,
  features: {},
};

const portalReducers = createReducer(
  initialState,
  on(LoginSuccess, state => ({ ...state, loggedIn: true })),
  on(Logout, state => ({
    ...state,
    loggingOut: true,
  })),
  on(LogoutComplete, () => ({
    ...initialState,
  })),
  on(LoginFailure, () => ({
    ...initialState,
  })),
  on(SelectPortalOrganization, state => ({
    ...state,
    currentOrganization: {
      ...state.currentOrganization,
      organization_id: undefined,
    },
  })),
  on(SelectPortalOrganizationSuccess, (state, { organizationId }) => ({
    ...state,
    currentOrganization: {
      ...state.currentOrganization,
      organization_id: organizationId,
    },
  })),
  on(SetPortalCurrentOrganizationRole, (state, { roles }) => ({
    ...state,
    currentOrganization: { ...state.currentOrganization, roles },
  })),
  on(PortalInfoLoad, state => ({
    ...state,
    loading: true,
  })),
  on(
    PortalInfoLoaded,
    (
      state,
      {
        portalInfo: {
          business_information,
          current_organization,
          organizations,
          services,
          marketplace,
          features,
          change_request,
          notifications,
          sub,
          name,
          locale,
          ext_identified,
          stc,
          email_verified,
          proposed_email,
          current_quarterly_review_status
        },
      }
    ) => ({
      ...state,
      businessInformation: { ...business_information },
      // @ts-ignore
      currentOrganization: current_organization,
      organizations,
      services,
      marketplace,
      features,
      // @ts-ignore
      changeRequest: change_request,
      notifications: { ...notifications },
      profile: {
        sub,
        name,
        locale,
        ext_identified,
        stc,
        email_verified,
        proposed_email,
        current_quarterly_review_status
      },
      loading: false,
      loaded: true,
    })
  ),
  on(UpdatePortalInfo, (state, { profile }) => ({
    ...state,
    profile: { ...state.profile, ...profile },
  })),
  on(UpdatePortalRequiredFields, (state, { missingFields }) => ({
    ...state,
    missingFields,
  })),
  on(PortalInfoError, state => ({ ...state, loaded: true, loading: false })),
  on(LoadChangeRequestsSuccess, (state, { changeRequest }) => ({
    ...state,
    // @ts-ignore
    changeRequest: changeRequest
      ? { req_type: changeRequest.req_type, status: changeRequest.status }
      : undefined,
  })),
  on(ShowedRequiredFieldsDialog, state => ({
    ...state,
    showedMissingFielsdDialog: true,
  })),
  on(CancelActivationCodeRequestsSuccess, state => ({
    ...state,
    change_request: undefined,
  }))
);

export function reducer(
  state: PortalState | undefined,
  action: Action
): PortalState {
  return portalReducers(state, action);
}

export const getPortalState = createFeatureSelector<PortalState>('portal');
