import { Component } from '@angular/core';

import { Store } from '@ngrx/store';
import { Logout } from '../../../auth/store/actions/auth.actions';
import { PortalState } from '../../../store/reducers/portal.reducer';

@Component({
  selector: 'iam-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent {
  constructor(private store: Store<PortalState>) {
    this.store.dispatch(Logout());
  }
}
