import { ClassProvider, Injectable } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { environment } from '../environments/environment';
import { State } from './store/reducers/state';
import {
  AppHttpRequestFinished,
  AppHttpRequestStarted,
} from './store/actions/app.actions';
import { ShowSnackbar } from './libs/portbase-snackbar-components/src/lib/store/snackbar.actions';
import { Logout } from './auth/store/actions/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class AppInterceptor implements HttpInterceptor {
  constructor(private store: Store<State>) {}

  fileUploadRequest(url: string, method: string): boolean{
    return url.startsWith(environment.apiEndpoints.fileserverApiUrl) && method === 'PUT'
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /* eslint-enable @typescript-eslint/no-explicit-any */
    // Add httpOptions (p.e. authentication headers)
    // Set credentials to false when uploading to file server with PUT
    request = request.clone({
      withCredentials: this.fileUploadRequest(request.url, request.method) ? false : true,
    });

    // Starting the HTTP request
    this.store.dispatch(AppHttpRequestStarted());

    return next.handle(request).pipe(
      finalize(() => {
        this.store.dispatch(AppHttpRequestFinished());
      }),
      catchError(err => {
        const strippedUrl = request.url.replace(
          `${environment.apiEndpoints.resourcesApiUrl}/`,
          ''
        );

        // Show snackbar when error status is 5xx
        if (err.status >= 500) {
          const amznTraceId = err.headers.get('x-amzn-trace-id')
            ? err.headers.get('x-amzn-trace-id').substring(5)
            : '';

          this.store.dispatch(
            ShowSnackbar({
              message: amznTraceId,
              level: 'error',
            })
          );
        }

        // Logout the user when we receive a 401
        // unless the endpoint is `sessions/me` or we'll end up in an endless loop
        if (
          err instanceof HttpErrorResponse &&
          err.status === 401 &&
          strippedUrl !== 'sessions/me'
        ) {
          this.store.dispatch(Logout());
        }
        return throwError(err);
      })
    );
  }
}

export const APP_PROVIDER: ClassProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AppInterceptor,
  multi: true,
};
