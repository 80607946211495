import { createAction, props } from '@ngrx/store';
import {
  ReferenceTypeObjects,
  ReferenceTypesEnum,
} from '../../models/references/reference-types.interface';
import { ErrorMessage } from '../../models/error-message.interface';

export const loadReferenceData = createAction(
  '[References] Load reference data',
  props<{ refType: ReferenceTypesEnum; active?: boolean }>()
);

export const referenceDataLoaded = createAction(
  '[References] Reference data loaded',
  props<{ refType: ReferenceTypesEnum; payload: ReferenceTypeObjects[] }>()
);

export const referenceDataLoadError = createAction(
  '[References] Reference data load error',
  props<{ refType: ReferenceTypesEnum; error: ErrorMessage }>()
);
