import { Observable, of } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';

import { English } from './en';
import { Nederlands } from './nl';
import { Deutsch } from './de';

export class CustomLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<unknown> {
    if ('en' === lang) {
      return of(English.translations);
    } else if ('nl' === lang) {
      return of(Nederlands.translations);
    } else if ('de' === lang) {
      return of(Deutsch.translations);
    } else {
      return of({});
    }
  }
}
