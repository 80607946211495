import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

// Services
import { TranslateService } from '@ngx-translate/core';

// RXJS
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

// NGRX
import { select, Store } from '@ngrx/store';
import { LANGUAGES } from '../../../constants/languages';
import { PortalLocales } from '../../../models/portal/portal-info.interface';
import { DestroyService } from '../../../libs/shared/services/destroy.service';
import { portalQuery } from '../../../store/selectors/portal.selectors';
import { OrganizationsState } from '../../../store/reducers/organizations.reducer';
import { AppUpdateLanguage } from '../../../store/actions/app.actions';
import { ChangePortalLanguage, UpdatePortalInfo } from '../../../store/actions/portal.actions';

@Component({
  selector: 'iam-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  providers: [DestroyService],
})
export class LanguageSelectorComponent implements OnInit {
  headerForm: UntypedFormGroup;
  userId: string;
  languages = LANGUAGES;

  userProfile$ = this.store.pipe(
    select(portalQuery.getUserProfile),
    filter(userProfile => !!userProfile)
  );

  constructor(
    private unsubscribe$: DestroyService,
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private store: Store<OrganizationsState>
  ) {
    this.headerForm = this.formBuilder.group({
      displayLanguage: [this.translate.currentLang],
    });
  }

  ngOnInit(): void {
    this.headerForm.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged(
          (prev, curr) => prev.displayLanguage === curr.displayLanguage
        )
      )
      .subscribe(lang => {
        this.changeSelectedLanguage(lang.displayLanguage);
      });

    this.userProfile$.subscribe(userProfile => {
      if (userProfile.locale) {
        const userProfileLanguage = this.languages.find(
          lang => lang.locale === userProfile.locale
        );

        // logged in then overrule current language
        if (userProfileLanguage) {
          this.translate.use(userProfileLanguage.id);
          this.translate.currentLang = userProfileLanguage.id;
        }
        this.headerForm.patchValue({
          displayLanguage: this.translate.currentLang,
        });
      }
      if (userProfile.sub) {
        this.userId = userProfile.sub;
      }
    });
  }

  changeSelectedLanguage(language: string): void {
    this.translate.use(language);

    this.store.dispatch(AppUpdateLanguage({ language }));
    if (this.userId) {
      const languageCode = this.languages.find(lang => lang.id === language);
      const locale = languageCode
        ? languageCode.locale
        : this.translate.defaultLang;
      const profile = {
        locale: locale as PortalLocales,
      };

      this.store.dispatch(
        ChangePortalLanguage({ locale: locale as PortalLocales })
      );
      this.store.dispatch(UpdatePortalInfo({ profile }));
    }
  }
}
