import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// RXJS
import { of } from 'rxjs';
import { catchError, switchMap, map, tap } from 'rxjs/operators';

// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';

// Services
import { QuarterlyReviewService } from '../../core/services/quarterly-review.service';
import {
  GetQuarterlyReview,
  GetQuarterlyReviewSuccess, 
  GetQuarterlyReviewError,
  UpdateQuarterlyReview,
  UpdateQuarterlyReviewSuccess,
  UpdateQuarterlyReviewError
} from '../actions/quarterly-review.actions';
import { ShowSnackbar } from '../../libs/portbase-snackbar-components/src/lib/store/snackbar.actions';

import { PortalInfoReload } from '../actions/portal.actions';
import { Router } from '@angular/router';

@Injectable()
export class QuarterlyReviewEffects {
  constructor(
    private actions$: Actions,
    private qrService: QuarterlyReviewService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  getQuarterlyReview$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GetQuarterlyReview),
        switchMap(() => 
          this.qrService.getQuarterlyReview().pipe(
            map((quarterlyReview) =>
              GetQuarterlyReviewSuccess(quarterlyReview)
            ),
            catchError(({ error }) =>
              of(
                GetQuarterlyReviewError({ error })
              )
            )
          )
        )
      ),
      { dispatch: true }
  );

  getQuarterlyReviewError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GetQuarterlyReviewError),
        tap(() => 
          this.router.navigate(['/usermanagement'])
        )
      ),
      { dispatch: false }
  );

  updateQuarterlyReview$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UpdateQuarterlyReview),
        switchMap(({ members }) => 
          this.qrService.updateQuarterlyReview(members).pipe(
            switchMap((res) =>
              [
                UpdateQuarterlyReviewSuccess(res),
                ShowSnackbar({
                  title: this.translateService.instant(
                    members.length === 1 
                      ? 'userManagement.periodicValidation.feedback.success.singleTitle' 
                      : 'userManagement.periodicValidation.feedback.success.title'
                  , { count: members.length }),
                  level: 'success',
                  options: {
                    duration: 10000,
                  },
                }),
                GetQuarterlyReview(),
                PortalInfoReload()
              ]
            ),     
            catchError(({ error }) => {
              const errorString: string = error.Error;
         
              let title: string;
              let message: string;
              
              if (errorString === 'errorTexts.periodicValidation.alreadyCompleted') {
                title = 'userManagement.periodicValidation.feedback.alreadyCompleted.title';
                message = 'userManagement.periodicValidation.feedback.alreadyCompleted.message';
              } else if (errorString === 'errorTexts.general.forbidden') {
                title = 'userManagement.periodicValidation.feedback.featureUnavailable.title';
                message = 'userManagement.periodicValidation.feedback.featureUnavailable.message';
              } else if (errorString === 'errorTexts.periodicValidation.operationNotAllowed') {
                title = 'userManagement.periodicValidation.feedback.userAdminRestriction.title';
                message = 'userManagement.periodicValidation.feedback.userAdminRestriction.message';
              } else {
                title = 'errorTexts.errorSnackbar.title';
              }

              return of(
                UpdateQuarterlyReviewError({ error }),
                ShowSnackbar({
                  title: this.translateService.instant(title),
                  message: this.translateService.instant(message),
                  level: 'danger',
                }),
                GetQuarterlyReview(),
                PortalInfoReload()
              );
            })
          )
        )
      ),
    { dispatch: true }
  );
}
