import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControlBase } from '../form-control-base.component';
import {
  MatFormFieldAppearance,
  MatFormFieldControl,
} from '@angular/material/form-field';

import { isObservable, Observable, of } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { compareObject } from '../../../../../utils/compare-object.utils';

@Component({
  selector: 'gfc-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectComponent,
    },
  ],
})
export class SelectComponent extends FormControlBase<string> implements OnInit {
  @Input() totalList?: unknown[] = undefined;
  @Input() label;
  @Input() dataList: unknown[];
  @Input() dataValue = 'id';
  @Input() dataLabel = 'name';
  @Input() multiple = false;
  @Input() chipsOutput = false;
  @Output() selectionChange = new EventEmitter();
  @Input() loading = false;
  @Input() showTooltip = false;
  @Input() appearance?: MatFormFieldAppearance = 'fill';
  @Input() compareFunction?: (
    c1: string | unknown,
    c2: string | unknown
  ) => boolean;
  @Input() override hint: string;

  removableChips = true;

  override ngOnInit(): void {
    super.ngOnInit();

    if (this.disabled) {
      this.control.disable();
    }

    if (this.chipsOutput) {
      this.removableChips = this.control.enabled;
      this.multiple = true;

      this.control.statusChanges
        .pipe(
          filter(changes => changes === 'DISABLED' || changes === 'VALID'),
          takeUntil(this.destroy$)
        )
        .subscribe(() => (this.removableChips = this.control.enabled));
    }
  }

  removeItem(item: unknown): void {
    const items = [...this.control.value];
    this.removeFirst(items, item);
    this.control.setValue(items);
    this.control.markAsTouched();
  }

  compareControlWithDataList(
    c1: string | unknown,
    c2: string | unknown
  ): boolean {
    if (typeof c1 === 'object' && typeof c2 === 'object') {
      function checkObject(c1, c2): boolean {
        //check type at start
        if (typeof c1 !== typeof c2) return false;
        if (Array.isArray(c1) !== Array.isArray(c2)) return false;

        //case no children
        if (c1 && Object.keys(c1).length === 0) return c1 === c2;
        if (c1 == null) return c1 === c2; //case if c1 is nullish

        //in case of an array
        if (Array.isArray(c1)) {
          for (let i = 0; i < c1.length; i++) {
            if (!checkObject(c1[i], c2[i])) return false;
          }

          return true;
        } else {
          //general object case
          //Loop through properties in object 1
          for (const p in c1) {
            //Check property exists on both objects
            if (c1.hasOwnProperty(p) !== c2.hasOwnProperty(p)) return false;

            switch (typeof c1[p]) {
              //Deep compare objects
              case 'object':
                if (!checkObject(c1[p], c2[p])) return false;
                break;
              //Compare function code
              case 'function':
                if (
                  typeof c2[p] == 'undefined' ||
                  (p != 'compare' && c1[p].toString() != c2[p].toString())
                )
                  return false;
                break;
              //Compare values
              default:
                if (c1[p] !== c2[p]) return false;
            }
          }

          //Check object 2 for any extra properties
          for (const p in c2) {
            if (typeof c1[p] == 'undefined') return false;
          }

          return true;
        }
      }
      return checkObject(c1, c2);
    }
    return c1 === c2;
  }

  private removeFirst<T>(array: T[], toRemove: T | string): void {
    let index = -1;
    if (typeof array[0] === 'string') {
      index = array.findIndex(item => item === toRemove);
    } else if (typeof array[0] === 'object') {
      index = array.findIndex(
        arr => JSON.stringify(arr) === JSON.stringify(toRemove)
      );
    } else {
      index = array.findIndex(
        item => item[this.dataValue] === toRemove[this.dataValue]
      );
    }
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  getItemNameById(label: unknown): Observable<string> {
    if (!this.dataList) return of('');

    let item;

    if (typeof label === 'string') {
      item = this.dataList.find(item => item[this.dataValue] === label);
    }

    if (typeof label === 'object') {
      item = this.dataList.find(item =>
        compareObject(item[this.dataValue], label)
      );
      if (!item && this.totalList) {
        item = this.totalList.find(item =>
          compareObject(item[this.dataValue], label)
        );
      }
    }

    if (!item) return of('');

    const name = item[this.dataLabel];

    if (!isObservable(name)) {
      return of(name);
    }
    return name as Observable<string>;
  }

  selectionChanges($event: { value: string }): void {
    if ($event.value) {
      this.selectionChange.emit($event.value);
    }
  }
}
