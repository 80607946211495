import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { tap } from 'rxjs/operators';

import { SnackbarService } from '../services/snackbar.service';
import { SnackbarMessage } from '../models/snackbar-message.model';
import { ShowSnackbar } from './snackbar.actions';

@Injectable()
export class SnackbarEffects {
  constructor(
    private actions$: Actions,
    private snackbarService: SnackbarService
  ) {}

  showSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowSnackbar),
        tap(action => {
          const data: SnackbarMessage = {
            title: action.title,
            message: action.message,
            level: action.level,
          };
          this.snackbarService.show(data, action.options);
        })
      ),
    { dispatch: false }
  );
}
