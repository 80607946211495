import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ReferenceOrganizationType } from '../../models/references/organization-type.interface';
import { loadRolesSuccess } from '../actions/organization-roles.actions';

export interface OrganizationRoles {
  id: string;
  roles: ReferenceOrganizationType[];
}

export interface OrganizationRolesState extends EntityState<OrganizationRoles> {
  // additional entities state properties
  selectedOrgId: number | null;
}

export const adapter: EntityAdapter<OrganizationRoles> =
  createEntityAdapter<OrganizationRoles>();

export const initialState: OrganizationRolesState = adapter.getInitialState({
  // additional entity state properties
  selectedOrgId: null,
});

const userReducer = createReducer(
  initialState,
  on(loadRolesSuccess, (state, { id, roles }) => {
    return adapter.addOne({ id, roles }, state);
  })
);

export function reducer(
  state: OrganizationRolesState | undefined,
  action: Action
) {
  return userReducer(state, action);
}
