import { createSelector } from '@ngrx/store';
import { getReferencesState } from '../reducers/state';
import * as fromReferences from './../reducers/references.reducer';
import { ReferenceTypesEnum } from '../../models/references/reference-types.interface';

const getReferenceData = createSelector(
  getReferencesState,
  (state: fromReferences.ReferenceState, refType: ReferenceTypesEnum) => {
    const data = state[refType];
    if (data && data.loaded) {
      return data.list;
    }
    return [];
  }
);

const hasReferenceDataLoaded = createSelector(
  getReferencesState,
  (state: fromReferences.ReferenceState, refType: ReferenceTypesEnum) =>
    // @ts-ignore
    state[refType] && state[refType].loaded
);

export const referencesQuery = {
  getReferenceData,
  hasReferenceDataLoaded,
};
