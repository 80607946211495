import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// SERVICES
// RXJS
import { mergeMap } from 'rxjs/operators';
import { DialogService } from '../../services/dialog.service';

// NGRX
import { CloseDialog } from './../actions/dialog.action';

@Injectable()
export class DialogEffects {
  constructor(
    private actions$: Actions,
    private dialogService: DialogService
  ) {}

  closeDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CloseDialog),
      mergeMap(() => {
        this.dialogService.closeDialog();
        return [];
      })
    );
  });
}
