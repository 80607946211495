import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthEffects } from './store/effects/auth.effects';
import { LoginCallbackComponent } from './components/login-callback/login-callback.component';
import { ActivationEffects } from './store/effects/activation.effects';
import { ActivationCallbackComponent } from './components/activation-callback/activation-callback.component';

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    EffectsModule.forFeature([AuthEffects, ActivationEffects]),
  ],
  declarations: [LoginCallbackComponent, ActivationCallbackComponent],
})
export class AuthModule {}
