interface Language {
  id: string;
  name: string;
  locale: string;
  okta: string;
}

export const LANGUAGES: Array<Language> = [
  { id: 'nl', name: 'Nederlands', locale: 'nl_NL', okta: 'nl-NL' },
  { id: 'en', name: 'English', locale: 'en_US', okta: 'en' },
  { id: 'de', name: 'Deutsch', locale: 'de_DE', okta: 'de' },
];
