import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoticeBarComponent } from './notice-bar.component';



@NgModule({
  declarations: [NoticeBarComponent],
  imports: [
    CommonModule
  ],
  exports: [NoticeBarComponent]
})
export class NoticeBarModule { }
