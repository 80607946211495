
interface QuarterlyReviewConfig  {
    url: string;
    width: string;
}

export const quarterlyReviewConfig: QuarterlyReviewConfig[] = [
  {
    url: '/',
    width: 'w-2/3',
  },
  {
    url: '/profile/account-information',
    width: 'w-full',
  },
  {
    url: '/profile/business-information',
    width: 'w-full',
  },
  {
    url: '/organizations',
    width: 'w-full',
  },
  {
    url: '/servicemanagement',
    width: 'w-full',
  },
  {
    url: '/open-requests',
    width: 'w-full',
  }
];