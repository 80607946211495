import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { BeamerService } from '../../core/services/beamer.service';
import { AppLanguageUpdated, AppUpdateLanguage } from '../actions/app.actions';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private beamerService: BeamerService
  ) {}

  appUpdateLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppUpdateLanguage),
      tap(({ language }) => this.beamerService.updateLanguage(language)),
      map(({ language }) =>
        AppLanguageUpdated({
          language,
        })
      )
    )
  );
}
