import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import {
  ChangeRequestsState,
  reducer as changeRequestsReducer,
} from './change-requests.reducer';
import {
  OrganizationRolesState,
  reducer as organizationRolesReducer,
} from './organization-roles.reducer';
import { PortalState, reducer as portalReducer } from './portal.reducer';
import { profileReducer, ProfileState } from './profile.reducer';
import {
  OrganizationsState,
  reducer as organizationsReducer,
} from './organizations.reducer';
import {
  ActivationState,
  reducer as activatedReducer,
} from './activation.reducer';
import {
  reducer as referencesReducer,
  ReferenceState,
} from './references.reducer';
import { AppState, reducer as appReducer } from './app.reducer';

import { SidebarState, reducer as sidebarReducer } from './sidebar.reducer';

import { QuarterlyReviewState, reducer as quarterlyReviewReducer } from './quarterly-review.reducer';

export interface State {
  app: AppState;
  activated: ActivationState;
  profile: ProfileState;
  references: ReferenceState;
  router: RouterReducerState;
  organizations: OrganizationsState;
  portal: PortalState;
  changeRequests: ChangeRequestsState;
  organizationRoles: OrganizationRolesState;
  sidebar: SidebarState;
  quarterlyReview: QuarterlyReviewState;
}

export const reducers: ActionReducerMap<State> = {
  app: appReducer,
  activated: activatedReducer,
  profile: profileReducer,
  references: referencesReducer,
  router: routerReducer,
  organizations: organizationsReducer,
  portal: portalReducer,
  changeRequests: changeRequestsReducer,
  organizationRoles: organizationRolesReducer,
  sidebar: sidebarReducer,
  quarterlyReview: quarterlyReviewReducer,
};

export const getActivationState =
  createFeatureSelector<ActivationState>('activated');
export const getAppState = createFeatureSelector<AppState>('app');
export const getOrganizationsState =
  createFeatureSelector<OrganizationsState>('organizations');
export const getReferencesState =
  createFeatureSelector<ReferenceState>('references');
export const getRouterState =
  createFeatureSelector<RouterReducerState>('router');
export const getPortalState = createFeatureSelector<PortalState>('portal');
export const getProfileState = createFeatureSelector<ProfileState>('profile');
export const getSidebarState = createFeatureSelector<SidebarState>('sidebar');
export const getQuarterlyReviewState = createFeatureSelector<QuarterlyReviewState>('quarterlyReview');
