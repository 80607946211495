import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from 'projects/iam/src/app/models/error-message.interface';
import { UserProfile } from 'projects/iam/src/app/models/user/userprofile.interface';



export const LoadProfile = createAction('[Profile] Load profile');

export const ProfileLoaded = createAction(
  '[Profile] Profile loaded',
  props<{ profile: UserProfile }>()
);

export const ProfileLoadError = createAction(
  '[Profile] Profile load error',
  props<{ error: ErrorMessage }>()
);

export const UpdateProfile = createAction(
  '[Profile] Update profile',
  props<{ profile: Partial<UserProfile> }>()
);

export const UpdateProfilePassword = createAction(
  '[Profile] Update profile password',
  props<{ oldPassword: string; newPassword: string }>()
);

export const ProfileUpdatedSuccessfully = createAction(
  '[Profile] User updated successfully',
  props<{ profile: Partial<UserProfile> }>()
);

export const IdentifyAgain = createAction('[Profile] User identify again');

export const IdentifyAgainSuccess = createAction(
  '[Profile] User identify again success'
);

export const ResetMFA = createAction('[Profile] Reset MFA for user');

export const DeactivateUser = createAction('[Profile] Deactivate user');
