import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '../../../models/error-message.interface';

export const Login = createAction('[Login Page] Login');

export const LoginComplete = createAction('[Login Page] Login Complete');

export const LoginSuccess = createAction(
  '[Auth API] Login Success',
  props<{ userId: string }>()
);

export const LoginFailure = createAction(
  '[Auth API] Login Failure',
  props<{ payload: ErrorMessage }>()
);

export const CheckLogin = createAction('[Auth] Check Login');

export const Logout = createAction('[Auth] Logout');

export const LogoutComplete = createAction('[Auth] Logout Complete');
