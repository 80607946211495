<header
  id="page-header"
  class="flex flex-row justify-center"
  data-test="header"
>
  <div class="flex flex-row w-11/12 lg:w-9/12 justify-between">
    <ng-container>
      <a
        routerLink="/"
        class="logo flex flex-row justify-center items-center"
        data-test="logo"
      >
        <picture>
          <source
            srcset="assets/img/iamconnected_logo.webp"
            type="image/webp"
          />
          <img src="assets/img/iamconnected_logo.svg" />
        </picture>
      </a>
    </ng-container>

    <div class="flex flex-row mat-small items-center">

      <iam-beamer-button></iam-beamer-button>
      
      <iam-user-name *ngIf="!onMobile"></iam-user-name>

      <iam-organization-selector *ngIf="!onMobile && !(hideNavMenu$ | async)"></iam-organization-selector>

      <iam-language-selector *ngIf="!onMobile"></iam-language-selector>


      <iam-login-button *ngIf="!(hideNavMenu$ | async)"></iam-login-button>
    </div>
  </div>
</header>
