import { Provider } from '@angular/core';

import { Direction } from '@angular/cdk/bidi';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

export interface SnackbarOptions {
  snackbarOptionsProvider?: Provider;

  direction?: Direction; // Text layout direction for the snack bar
  duration?: number; // The length of time in milliseconds to wait before automatically dismissing the snack bar

  horizontalPosition?: MatSnackBarHorizontalPosition;
  verticalPosition?: MatSnackBarVerticalPosition;

  panelClass?: string | string[]; // Extra CSS classes to be added to the snack bar container

  showIcon?: boolean; // Show icons
  successIcon?: string; // Icon name of the success icon
  dangerIcon?: string; // Icon name of the danger icon
  infoIcon?: string; // Icon name of the info icon
  warningIcon?: string; // Icon name of the warning icon
}

export const defaultOptions: SnackbarOptions = {
  direction: 'ltr',
  horizontalPosition: 'center',
  verticalPosition: 'top',
  showIcon: true,
  successIcon: 'fa-check',
  dangerIcon: 'fa-circle-xmark',
  infoIcon: 'fa-circle-info',
  warningIcon: 'fa-circle-exclamation',
};
