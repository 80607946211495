import { Injectable } from '@angular/core';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { environment } from 'projects/iam/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RumService extends AwsRum {
  constructor() {
    try {
      const config: AwsRumConfig = {
        sessionSampleRate: 1,
        endpoint: environment.rum.endpoint,
        telemetries: [
          [
            'http',
            {
              logicalServiceName: 'rum.aws.amazon.com',
              urlsToInclude: [/.*/],
              urlsToExclude: [
                // Cognito endpoints https://docs.aws.amazon.com/general/latest/gr/cognito_identity.html
                /cognito\-identity\.([^\.]*\.)?amazonaws\.com/,
                // STS endpoints https://docs.aws.amazon.com/general/latest/gr/sts.html
                /sts\.([^\.]*\.)?amazonaws\.com/,
                /auth\.dev-iamconnected\.eu/,
                /autn\.dev-iamconnected\.eu/,
                /dev-iamconnected\.eu\/auth\/*/,
                /auth\.test-iamconnected\.eu/,
                /autn\.test-iamconnected\.eu/,
                /test-iamconnected\.eu\/auth\/*/,
                /auth\.preview-iamconnected\.eu/,
                /autn\.preview-iamconnected\.eu/,
                /preview-iamconnected\.eu\/auth\/*/,
                /auth\.iamconnected\.eu/,
                /iamconnected\.eu\/auth\/*/,
                /autn\.iamconnected\.eu/,
                /getbeamer\.com/,
                /.*\.svg$/
              ],
              stackTraceLength: 500,
              recordAllRequests: true,
              addXRayTraceIdHeader: true,
            },
          ],
          'errors',
          'performance',
        ],
        allowCookies: true,
        enableXRay: true,
        dispatchInterval: 5000,
        guestRoleArn: environment.rum.guestRoleArn,
        identityPoolId: environment.rum.identityPoolId,
      };

      const APPLICATION_ID: string = environment.rum.id;
      const APPLICATION_VERSION: string = environment.rum.version;
      const APPLICATION_REGION: string = environment.rum.region;

      super(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }

}
