import { Action, createReducer, on } from '@ngrx/store';
import * as ReferenceActions from './../actions/references.actions';
import { ReferenceTypesEnum } from '../../models/references/reference-types.interface';
import { ReferenceOrganizationType } from '../../models/references/organization-type.interface';

interface ReferenceSlice<T> {
  list: Array<T>;
  loaded: boolean;
  error?: boolean;
}

export interface ReferenceState {
  [ReferenceTypesEnum.ORGANIZATION_TYPES]?: ReferenceSlice<ReferenceOrganizationType>;
}

export const initialState: ReferenceState = {};

const referencesReducer = createReducer(
  initialState,
  on(ReferenceActions.referenceDataLoaded, (state, { refType, payload }) => ({
    ...state,
    [refType as string]: { list: payload, loaded: true },
  }))
);

export function reducer(
  state: ReferenceState | undefined,
  action: Action
): ReferenceState {
  return referencesReducer(state, action);
}
