<div id="content" class="flex flex-row flex-grow justify-center">
  <div
    class="flex flex-row w-11/12 lg:w-9/12 justify-between"
  >
    <div class="router-container">
      <router-outlet></router-outlet>
    </div>
  </div>

  <iam-cookie-banner></iam-cookie-banner>
  <iam-customer-service-button></iam-customer-service-button>
</div>
