import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'iam-not-found',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss'],
})
export class NotFoundComponent {
  constructor(private router: Router) {}

  navigateToHome(): void {
    this.router.navigateByUrl('/');
  }
}
