import { GetTooltipTranslationPipe } from './tooltip-translation.pipe/tooltip-translation.pipe';
import { ConvertSnakeCaseToCamelCasePipe } from './string-conversion/convert-snake-case-to-camel-case.pipe';
import { NgModule } from '@angular/core';
import { ConvertIntDateToEuFormatPipe } from './date-conversion/convert-int-date-to-eu-format.pipe';
import { ConvertEuDateToIntFormatPipe } from './date-conversion/convert-eu-date-to-int-format.pipe';
import { GetTranslationFoundPipe } from './translation-found/translation-found.pipe';
import { ShowWarning } from './show-warning/show-warning.pipe';
import { ConvertIntDateToDistanceDate } from './date-conversion/convert-int-date-to-distance-date.pipe';
import { FileSizePipe } from './filesize/filesize.pipe';

@NgModule({
  declarations: [
    ConvertIntDateToEuFormatPipe,
    ConvertEuDateToIntFormatPipe,
    ConvertSnakeCaseToCamelCasePipe,
    ConvertIntDateToDistanceDate,
    GetTooltipTranslationPipe,
    GetTranslationFoundPipe,
    ShowWarning,
    FileSizePipe
  ],
  providers: [],
  exports: [
    ConvertIntDateToEuFormatPipe,
    ConvertEuDateToIntFormatPipe,
    ConvertSnakeCaseToCamelCasePipe,
    ConvertIntDateToDistanceDate,
    GetTooltipTranslationPipe,
    GetTranslationFoundPipe,
    ShowWarning,
    FileSizePipe
  ],
})
export class GenericPipesModule {}
