import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IamRestService } from 'projects/iam/src/app/core/services/iam-rest.service';

// RXJS
import { Observable } from 'rxjs';

// NGRX
import { Store } from '@ngrx/store';
import { Organization } from '../../../models/organization/organization.interface';

// MODELS

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService extends IamRestService {
  constructor(private _http: HttpClient, private _store: Store) {
    super(_http, _store);
  }

  findOrganizations(searchString: string): Observable<Organization[]> {
    const url = `${
      this.baseUrl
    }/organizations/:userId?query=${encodeURIComponent(searchString)}`;
    return this.get(url);
  }

  getOrganization(organizationId: string): Observable<Organization> {
    const url = `${this.baseUrl}/organizations/:userId/${organizationId}`;
    return this.get(url);
  }

  updateOrganization(
    organization: Partial<Organization>
  ): Observable<Organization> {
    const url = `${this.baseUrl}/organizations/:userId/${organization.id}`;
    return this.put(url, organization);
  }
}
