import { Action, createReducer, on } from '@ngrx/store';
import * as AppActions from './../actions/app.actions';
import { ErrorMessage } from '../../models/error-message.interface';

export const APP_FEATURE_KEY = 'app';

// We export the function so we can test it
export const browserLanguage = (): string => {
  const browserLanguage = navigator.language || navigator['userLanguage'];
  const language = browserLanguage.substr(0, 2);
  if (language === 'de' || language === 'en') {
    return language;
  }
  return 'nl';
};

export interface AppState {
  selectedLanguage: string;
  loaded: boolean;
  apisLoading: number;
  error?: ErrorMessage;
}

export interface AppPartialState {
  readonly [APP_FEATURE_KEY]: AppState;
}

export const initialState: AppState = {
  selectedLanguage: browserLanguage(),
  apisLoading: 0,
  loaded: false,
};

const appReducers = createReducer(
  initialState,
  on(AppActions.AppLanguageUpdated, (state, { language }) => ({
    ...state,
    selectedLanguage: language,
  })),
  on(AppActions.AppHttpRequestStarted, state => ({
    ...state,
    apisLoading: state.apisLoading + 1,
  })),
  on(AppActions.AppHttpRequestFinished, state => ({
    ...state,
    apisLoading: state.apisLoading - 1,
  }))
);

export function reducer(state: AppState | undefined, action: Action): AppState {
  return appReducers(state, action);
}
