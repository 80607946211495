import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { portalQuery } from '../../store/selectors/portal.selectors';
import { DefaultUserGroups } from '../../models/default-user-groups.interface';
import { Observable, map } from 'rxjs';

@Injectable()
export class UserGroupGuard {
  $userRole = this.store.select(portalQuery.getUserMaxRole);
  constructor(private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const expectedRoles = route.data['allowedFor'] as DefaultUserGroups[];
    return this.$userRole.pipe(map(userRole => expectedRoles.includes(userRole)));
  }
}
