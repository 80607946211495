import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LogoutComponent} from 'projects/iam/src/app//core/components/logout/logout.component';
import {NotFoundComponent} from './core/components/notfound/notfound.component';
import {AuthenticationGuard} from './core/guards/authentication-guard';

/*eslint-disable @typescript-eslint/explicit-function-return-type */
const routes: Routes = [
  {
    path: '',
    loadChildren: async () =>
      (await import('projects/iam/src/app/features/home/feature-home.module'))
        .IamFeatureHomeModule,
  },
  {
    path: '',
    canActivate: [AuthenticationGuard],
    loadChildren: async () =>
      (await import('projects/iam/src/app/app-auth-routing.module')).AppAuthRoutingModule,
  },
  {
    path: 'auth',
    loadChildren: async () => (await import('./auth/auth.module')).AuthModule,
  },
  {
    path: 'docs',
    loadChildren: async () =>
      (await import('projects/iam/src/app/features/docs/feature-docs.module'))
        .FeatureDocsModule,
  },
  {
    path: 'verify_email',
    loadChildren: async () =>
      (await import('projects/iam/src/app/features/verify-email/verify-email.module'))
        .VerifyEmailModule,
  },
  {
    path: 'checkedid',
    loadChildren: async () =>
      (await import('projects/iam/src/app/features/checkedid-qr/checkedid-qr.module'))
        .CheckedIdQrModule,
  },
  { path: 'logout', component: LogoutComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
