<ul *ngrxLet="loggedIn$ as loggedIn" class="navigation-items mat-subtitle-2" [class.sidenav-container]="isSidenav">
  <li *ngIf="onMobile">
    <iam-user-name></iam-user-name>
  </li>
  <li>
    <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="onSidenavClose()"
      data-test="nav-home">
      <span *ngIf="!loggedIn">{{ 'home.title' | translate }}</span>
      <span *ngIf="loggedIn">{{ 'home.titleLoggedIn' | translate }}</span>
    </a>
  </li>
  <li *ngIf="!loggedIn">
    <a routerLink="/register" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
      (click)="onSidenavClose()" data-test="nav-register">
      {{ 'register.title' | translate }}
    </a>
  </li>
  <ng-container *ngIf="loggedIn">
    <li>
      <a [routerLink]="'/' + portalPages.PROFILE" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        (click)="onSidenavClose()" data-test="nav-profile">
        {{ 'myProfile.title' | translate }}
      </a>
    </li>
    <li>
      <a [routerLink]="'/' + portalPages.ORGANIZATIONS" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }" (click)="onSidenavClose()" data-test="nav-organizations">{{
        'organizationsManagement.title' | translate }}</a>
    </li>
    <li *ifInRole="userGroups.USERMANAGERS">
      <a [routerLink]="'/' + portalPages.USERMANAGEMENT" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }" (click)="onSidenavClose()" data-test="nav-usermanagement">
        <ng-container *ngrxLet="notifications$ as notifications">
          <span class="inline-block hide-text" [attr.data-test]="'badge-usermanagement'" [matBadge]="notifications"
            matBadgeOverlap="false" matBadgePosition="after" matBadgeColor="warn" matBadgeSize="small"
            [matBadgeHidden]="notifications === 0">{{ 'userManagement.title' | translate }}</span>
        </ng-container>
      </a>
    </li>

    <li *ngIf="isServiceManager$ | async">
      <a [routerLink]="'/' + portalPages.SERVICEMANAGEMENT" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }" (click)="onSidenavClose()" data-test="nav-servicemanagement">{{
        'serviceManagement.title' | translate }}</a>
    </li>

    <li *ngIf="openChangeRequest$ | async">
      <a [routerLink]="'/' + portalPages.OPEN_REQUESTS" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }" (click)="onSidenavClose()" data-test="nav-open-requests">
        <span class="inline-block" data-test="badge-open-request" [matBadge]="1" matBadgeOverlap="false"
          matBadgePosition="after" matBadgeColor="warn" matBadgeSize="small">{{ 'openRequests.title' | translate
          }}</span>
      </a>
    </li>
  </ng-container>
  <li>
    <a href="https://support.portbase.com/iamconnected/" target="_blank" (click)="onSidenavClose()"
      data-test="nav-help">
      {{ 'help.title' | translate }}
    </a>
  </li>
  <li *ngIf="onMobile">
    <iam-language-selector></iam-language-selector>
  </li>
</ul>