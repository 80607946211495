import { Action, createReducer, on } from '@ngrx/store';
import {
  LoadProfile,
  ProfileLoaded,
  ProfileLoadError,
  ProfileUpdatedSuccessfully,
} from '../../features/profile/store/profile/profile.actions';
import { updateBusinessInformationSuccess } from '../actions/profile.actions';
import { ErrorMessage } from '../../models/error-message.interface';

export const PROFILE_FEATURE_KEY = 'profile';

export interface UserPersonalInformation {
  identification?: Identification;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  secondEmail: string;
  login: string;
  locale: string;
  mfaEnabled: boolean;
  extIdentified?: boolean;
  email_unavailable?: boolean;
  email_unavailability_reason?: string;
}

export interface ProfileState {
  personalInformation: UserPersonalInformation;
  loading: boolean;
  loaded: boolean;
  error?: ErrorMessage;
  extIdentified?: boolean;
  identification?: Identification;
}

export enum IdentificationStatus {
  APPROVED,
  REJECTED,
}
export interface Identification {
  full_name: string;
  date_of_birth: string;
  document_type: string;
  document_number: string;
  expiry_date: string;
  status: IdentificationStatus;
}

export const profileInitialState: ProfileState = {
  personalInformation: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
    secondEmail: '',
    login: '',
    locale: '',
    // @ts-ignore
    mfaEnabled: undefined,
  },
  loading: false,
  loaded: false,
};

const profileReducers = createReducer(
  profileInitialState,
  on(LoadProfile, state => ({
    ...state,
    loading: true,
  })),
  on(ProfileLoaded, (state, { profile }) => ({
    ...state,
    personalInformation: {
      ...profile,
      ...{ id: profile.id },
      ...{ mfaEnabled: profile.mfaEnabled },
    },
    loading: false,
    loaded: true,
  })),
  on(ProfileUpdatedSuccessfully, (state, { profile }) => ({
    ...state,
    personalInformation: {
      ...state.personalInformation,
      ...profile,
    },
    loading: false,
    loaded: true,
  })),
  on(updateBusinessInformationSuccess, (state, { payload }) => ({
    ...state,
    businessInformation: payload,
  })),
  on(ProfileLoadError, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: true,
    error,
  }))
);

export function profileReducer(
  state: ProfileState | undefined,
  action: Action
): ProfileState {
  return profileReducers(state, action);
}
