import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';

// RXJS
import { DestroyService } from '../../../libs/shared/services/destroy.service';

@Component({
  selector: 'iam-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  providers: [DestroyService],
})
export class NavComponent {
  @Output() sidenavToggle = new EventEmitter();

  hideNavMenu$ = this.router.events
  .pipe(filter(event => event instanceof NavigationEnd),
    map(() => {
      let route = this.activatedRoute.firstChild;
      let child = route;
      while (child) {
        if (child.firstChild) {
          child = child.firstChild;
          route = child;
        } else {
          child = null;
        }
      }
      return route;
    }),
    mergeMap(route => route.data),
    map(data => Boolean(data?.hideNavMenu))
  );

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {

  }

  onToggleSidenav(): void {
    this.sidenavToggle.emit();
  }
}
