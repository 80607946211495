import { Component, OnInit, Input } from "@angular/core";
import { NOTICECONFIGS, NoticeTypes } from "./notice-bar.const";

export interface INoticeType {
    type: NoticeTypes;
    bgColor: string;
    textColor: string;
    borderColor: string;
    icon: string;
    default: boolean;
}

@Component({
    selector: 'iam-notice-bar',
    templateUrl: './notice-bar.component.html',
    styleUrls: [],
})
export class NoticeBarComponent implements OnInit {
    typeConfig: INoticeType;
    @Input() private readonly type: NoticeTypes;

    ngOnInit() {
        this.typeConfig = NOTICECONFIGS.find((config) => config.type === this.type) 
            || NOTICECONFIGS.find((config) => config.default);
    }
}