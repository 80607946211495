import { INoticeType } from './notice-bar.component';

export enum NoticeTypes {
    INFO = "info",
    INFO_WHITE = "info_white",
    WARNING = "warning",
    DANGER = "danger",
};

export const NOTICECONFIGS: INoticeType[] = [
    { 
        type: NoticeTypes.INFO, 
        bgColor: 'bg-background',
        textColor: 'text-blue-500',
        borderColor: 'border-pb-blue-300',
        icon: 'fa-circle-info',
        default: true,
    },
    { 
        type: NoticeTypes.INFO_WHITE, 
        bgColor: 'bg-white',
        textColor: 'text-blue-500',
        borderColor: 'border-pb-blue-300',
        icon: 'fa-circle-info',
        default: true,
    },
    { 
        type: NoticeTypes.WARNING, 
        bgColor: 'bg-orange-50',
        textColor: 'text-orange-500',
        borderColor: 'border-orange-400',
        icon: 'fa-triangle-exclamation',
        default: false,
    },
    { 
        type: NoticeTypes.DANGER, 
        bgColor: 'bg-pb-red-100',
        textColor: 'text-pb-red-800',
        borderColor: 'border-pb-red-300',
        icon: 'fa-triangle-exclamation',
        default: false,
    },
];