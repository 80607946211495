import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as organizationRolesAction from '../actions/organization-roles.actions';
import { of } from 'rxjs';
import { OrganizationsService } from '../../core/services/organizations.service';

@Injectable()
export class OrganizationRolesEffects {
  constructor(
    private actions$: Actions,
    private organizationsService: OrganizationsService
  ) {}

  loadRoles$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(organizationRolesAction.loadRoles),
        switchMap(({ id }) =>
          this.organizationsService.getRoles(id).pipe(
            map(roles =>
              organizationRolesAction.loadRolesSuccess({ roles, id })
            ),
            catchError(({ error }) =>
              of(organizationRolesAction.loadRolesError({ error }))
            )
          )
        )
      ),
    { dispatch: true }
  );
}
