/* Plaats hier modules/componenten en services die in de hele app beschikbaar moeten zijn*/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MDModule } from './md.module';

import { FormControlBase } from './components/form-control-base.component';

import { AutoCompleteComponent } from './components/autocomplete/autocomplete.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { InputComponent } from './components/input/input.component';
import { ReCaptchaComponent } from './components/recaptcha/recaptcha.component';
import { SelectComponent } from './components/select/select.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { ReadonlyFieldComponent } from './components/readonly-field/readonly-field.component';
import { SkeletonLoaderModule } from '../../../shared/components/iam-skeleton-loader/skeleton-loader.module';
import { GenericPipesModule } from '../../../pipes/src/lib/pipes.module';
import { TurnstileModule } from './components/turnstile/turnstile.module';

@NgModule({
  imports: [
    CommonModule,
    MDModule,
    FormsModule,
    ReactiveFormsModule,
    SkeletonLoaderModule,
    GenericPipesModule,
    TurnstileModule
  ],
  exports: [
    AutoCompleteComponent,
    CheckboxComponent,
    InputComponent,
    ReCaptchaComponent,
    FormsModule,
    ReactiveFormsModule,
    SelectComponent,
    ToggleComponent,
    ReadonlyFieldComponent,
    TurnstileModule
  ],
  declarations: [
    AutoCompleteComponent,
    CheckboxComponent,
    FormControlBase,
    InputComponent,
    ReCaptchaComponent,
    SelectComponent,
    ToggleComponent,
    ReadonlyFieldComponent,
  ],
})
export class GenericFormComponentsModule {}
