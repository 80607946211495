import {createFeatureSelector, createSelector} from '@ngrx/store';
import {APP_FEATURE_KEY, AppState} from '../reducers/app.reducer';

// Lookup the app feature state managed by NgRx
const getAppState = createFeatureSelector<AppState>(APP_FEATURE_KEY);

const getLoaded = createSelector(
  getAppState,
  (state: AppState) => state.loaded
);

const getError = createSelector(getAppState, (state: AppState) => state.error);

const getApisLoading = createSelector(
  getAppState,
  (state: AppState) => state?.apisLoading > 0
);

const getSelectedLanguage = createSelector(
  getAppState,
  (state: AppState) => state.selectedLanguage
);

const getSelectedApp = createSelector(getSelectedLanguage, (app, id) => {
  const result = app.find(it => it['id'] === id);
  return result ? Object.assign({}, result) : undefined;
});

export const appQuery = {
  getApisLoading,
  getLoaded,
  getError,
  getSelectedApp,
  getSelectedLanguage,
};
