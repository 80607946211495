import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

// RXJS
import { isObservable, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';
import { SnackbarState } from '../../store/snackbar.reducer';
import { SnackbarMessage } from '../../models/snackbar-message.model';
import { SnackbarClosed } from '../../store/snackbar.actions';
import { DestroyService } from '../../../../../shared/services/destroy.service';

const iconMap = {
  danger: 'dangerIcon',
  success: 'successIcon',
  info: 'infoIcon',
  warning: 'warningIcon',
};

@Component({
  selector: 'psbc-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  providers: [DestroyService],
})
export class SnackbarComponent {
  constructor(
    private store: Store<SnackbarState>,
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarMessage,
    private unsubscribe$: DestroyService
  ) {
    this.snackBarRef
      .afterDismissed()
      .pipe(take(1))
      .subscribe(() => {
        this.store.dispatch(SnackbarClosed());
      });
  }

  get icon(): string {
    const iconAttribute = iconMap[this.data.level];
    return this.data[iconAttribute];
  }

  generateTextObservable(
    message: Observable<string> | string
  ): Observable<string> {
    return isObservable(message) ? message : of(message);
  }

  close(): void {
    this.snackBarRef.dismiss();
  }
}
