export const sharedTranslations = {
  organizationTypesDescription: {
    BARGE_OPERATOR: '',
    CARGADOOR_SHIPAGENT:
      'De cargadoor / shipagent is van oudsher de vertegenwoordiger van één of meerdere rederijen in een haven. De cargadoor / shipagent houdt zich bezig met scheeps- en bemanningszaken en anderzijds treedt hij/zij op als vertegenwoordiger van de ladingbelanghebbenden.',
    CUSTOMS: '',
    EMPTY_DEPOT: '',
    EXPORTER: '',
    FOOD_HEALTH_SERVICE: '',
    FORWARDER: '',
    IMPORTER: '',
    INSPECTION_STATION: '',
    IT_SERVICE_PROVIDER:
      'Een IT service provider biedt de levering en het beheer van netwerkgebaseerde diensten, toepassingen aan bedrijven',
    PORT_AUTHORITY: '',
    RAIL_HAULIER: '',
    RAIL_OPERATOR: '',
    RAILWAY_AUTHORITY: '',
    ROAD_HAULIER: '',
    SHIPPING_COMPANY: '',
    SKIPPER: '',
    TERMINAL: '',
  },
  organizationRolesDescription: {
    EMPLOYEE: 'Werknemer van een organisatie',
    FINANCIAL_DECLARANT: 'Een financieel declarant is verantwoordelijk voor de formaliteiten en aangifte van een of meerdere havenlogistieke processen.',
    IAM_ADMIN: '',
    ID_CHECKER: '',
    OPERATOR_SHIPPING_CLERK: 'Verantwoordelijk voor de formele verschepings- en vrachtdocumenten.',
    SCHEME_ADMIN: '',
    SD_EMPLOYEE: '',
    SD_STC: ''
  }
};
