import {
  Component,
  ElementRef,
  Host,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SkipSelf,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NgControl,
  ValidationErrors,
} from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatFormFieldControl } from '@angular/material/form-field';
import { isObservable, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

interface FormErrorMessage {
  [key: string]: string | Observable<string>;
}

@Component({
  template: '',
  host: {
    '(blur)': 'onTouched($event)',
  },
})
export class FormControlBase<T>
  implements ControlValueAccessor, MatFormFieldControl<T>, OnInit, OnDestroy {
  static nextId = 0;

  private _focused = false;
  private _placeholder = '';
  private _required = false;
  private _readonly = false;
  protected destroy$: Subject<void> = new Subject();

  control: FormControl;
  stateChanges: Subject<void> = new Subject();

  @Input() formControlName: string;
  @Input() hint: string;
  @Input() errors: FormErrorMessage | string;
  error$: Observable<string>;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get value(): T {
    return this.control ? this.control.value : undefined;
  }
  set value(value: T) {
    if (this.control) {
      this.control.setValue(value);
      this.stateChanges.next();
    }
  }

  @Input()
  set disabled(val: boolean) {
    if (this.control) {
      if (val) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }
  get disabled(): boolean {
    return this.control.disabled;
  }

  @HostBinding('attr.aria-describedby')
  describedBy = '';

  @HostBinding()
  id = `input-control-${++FormControlBase.nextId}`;

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }
  get focused(): boolean {
    return this._focused;
  }
  set focused(value: boolean) {
    this._focused = value;
    this.stateChanges.next();
  }
  get empty(): boolean {
    return !this.control || !this.control.value;
  }
  get errorState(): boolean {
    return this.ngControl.control !== null ? !!this.ngControl.control : false;
  }

  constructor(
    @Inject(ElementRef) private elementRef: ElementRef<HTMLElement>,
    @Inject(NgControl) @Optional() @Self() public ngControl: NgControl | null,
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer
  ) {
    if (ngControl) {
      // Set the value accessor directly (instead of providing NG_VALUE_ACCESSOR) to avoid running into a circular import
      this.ngControl.valueAccessor = this;
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (this.controlContainer && this.formControlName) {
      const parentControl = this.controlContainer.control.get(
        this.formControlName
      );
      this.control = parentControl as FormControl;

      // Set the disabled attribute
      this.disabled = this.control.disabled;

      // Set the required attribute
      if (typeof this.control.validator === 'function') {
        const validator = this.control.validator({} as AbstractControl);
        if (validator && validator.required) {
          this.required = true;
        }
      }

      this.control.statusChanges
        .pipe(takeUntil(this.destroy$), distinctUntilChanged())
        .subscribe(() => this.checkAndHandleErrors());

      // Bind the onChange function
      this.control.valueChanges
        .pipe(takeUntil(this.destroy$), distinctUntilChanged())
        .subscribe(val => {
          this.onChange(val);
          this.checkAndHandleErrors();
        });
    }
  }

  private checkAndHandleErrors(): void {
    if (this.control.errors) {
      this.formatErrors(this.control.errors);
    } else {
      this.error$ = of('');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.stateChanges.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched(): void {}
  // eslint-disable-next-line
  onChange(_: T): void {}

  // eslint-disable-next-line
  registerOnChange(onChanged: (v: any) => void): void {
    this.onChange = onChanged;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      const element = this.elementRef.nativeElement.querySelector('input');
      if (element) element.focus();
    }
  }

  // eslint-disable-next-line
  writeValue(value: T | null): void {}

  getErrorMessage(err: string | Observable<string>): Observable<string> {
    if (!isObservable(err)) {
      return of(err);
    }
    return err;
  }

  formatErrors(errors: ValidationErrors): void {
    if (!this.errors) return;

    if (typeof this.errors === 'string') {
      this.error$ = this.getErrorMessage(this.errors);
    } else {
      const errorMessages = Object.entries(errors).filter(
        ([key]) => !!this.errors[key]
      );
      if (errorMessages.length > 0) {
        const errorMessage = errorMessages[0];
        const errorObservable$ = this.getErrorMessage(
          this.errors[errorMessage[0]]
        );

        this.error$ = errorObservable$.pipe(
          map(message => this.formatError(message, errorMessage[1]))
        );
      }
    }
  }

  formatError(message: string, errorParams: { [key: string]: string }): string {
    for (const [key, value] of Object.entries(errorParams)) {
      message = message.replace(`{{ ${key} }}`, value);
    }
    return message;
  }
}
