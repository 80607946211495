import {createAction, props} from '@ngrx/store';
import {UserBusinessProfileBase} from '../../models/user/user-business-profile.interface';

// Business Information
export const updateBusinessInformation = createAction(
  '[Profile] Update business information',
  props<{ payload: UserBusinessProfileBase }>()
);

export const updateBusinessInformationSuccess = createAction(
  '[Profile] Update business information success',
  props<{ payload: UserBusinessProfileBase }>()
);
