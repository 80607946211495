<div
  class="cookie-banner fixed lg:w-1/2 w-full min-h-32 left-0 lg:left-1/4 bottom-0 shadow-2xl p4 flex flex-row flex-wrap content-center"
  *ngIf="showCookieBanner"
  data-test="cookie-banner"
>
  <div class="hidden lg:flex lg:flex-initial lg:opacity-100 items-center">
    <img style="width: 112px" src="/assets/img/cookies.jpg" />
  </div>
  <div class="flex-1">
    <p class="p-4 m-0">
      <span [innerHtml]="'cookieBanner.content' | translate"></span>
      <a class="font-bold" routerLink="docs/cookies">{{
        'cookieBanner.readmore' | translate
      }}</a>
    </p>
  </div>
  <div class="flex-initial m-4">
    <div class="pointer" (click)="close()" data-test="close-cookie-banner">
      <i class="fa-light fa-xmark fa-lg"></i>
    </div>
  </div>
</div>
