import { Injectable } from '@angular/core';

// RXJS
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

// NGRX
import { TranslateService } from '@ngx-translate/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// Services
import { Store } from '@ngrx/store';
import { UsersService } from '../../features/profile/services/users.service';
import { PortalPages } from '../../models/portal/portal-pages';
import { portalQuery } from '../selectors/portal.selectors';
import { PortalInfoLoad } from '../actions/portal.actions';
import {
  updateBusinessInformation,
  updateBusinessInformationSuccess,
} from '../actions/profile.actions';
import { CloseDialog } from '../../libs/portbase-dialog-component/lib/store/actions/dialog.action';
import { ShowSnackbar } from '../../libs/portbase-snackbar-components/src/lib/store/snackbar.actions';

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private translateService: TranslateService,
    private usersService: UsersService
  ) {}

  // @ts-ignore
  userId$: Observable<string> = this.store.select(portalQuery.getUserId);
  // Business information
  updateBusinessInformationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateBusinessInformationSuccess),
        switchMap(() => this.userId$),
        switchMap(userId => [
          CloseDialog(),
          PortalInfoLoad({
            userId,
            portalPage: PortalPages.PROFILE,
          }),
        ])
      ),
    { dispatch: true }
  );

  updateBusinessInformation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateBusinessInformation),
        switchMap(({ payload }) =>
          this.usersService.updateBusinessInformation(payload).pipe(
            switchMap(() => [
              updateBusinessInformationSuccess({ payload }),
              ShowSnackbar({
                message: this.translateService.instant(
                  'successTexts.updateUser'
                ),
                level: 'success',
              }),
            ]),
            catchError(({ error }) => {
              const errorMessage = this.translateService.instant(
                error.Error || 'errorTexts.general.badRequest'
              );

              return of(
                ShowSnackbar({
                  message: errorMessage,
                  level: 'danger',
                })
              );
            })
          )
        )
      ),
    { dispatch: true }
  );
}
