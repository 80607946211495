import { createAction, props } from '@ngrx/store';

import { QuarterlyReview } from '../../models/quarterly-review/quarterly-review.interface';

export const UpdateQuarterlyReview = createAction(
    '[Quarterly Review] Update',
    props<{ members: string[] }>()
);

export const UpdateQuarterlyReviewSuccess = createAction(
    '[Quarterly Review] Update success',
    props<any>()
);

export const UpdateQuarterlyReviewError = createAction(
    '[Quarterly Review] Update error',
    props<{ error: string }>()
);


export const GetQuarterlyReview = createAction(
    '[Quarterly Review] Get init'
);

export const GetQuarterlyReviewSuccess = createAction(
    '[Quarterly Review] Get success',
    props<QuarterlyReview>()
);

export const GetQuarterlyReviewError = createAction(
    '[Quarterly Review] Get error',
    props<{ error: string }>()
);

export const ResetQuarterlyReviewMembers = createAction(
    '[Quarterly Reset Quarterlyreview members'
);