import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

// NGRX
import { Store } from '@ngrx/store';
import * as fromDialogAction from './../store/actions/dialog.action';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  currentDialog: MatDialogRef<any>;

  constructor(private store: Store, private dialog: MatDialog) {}

  openDialog(
    componentOrTemplateRef: ComponentType<any> | TemplateRef<any>,
    config: MatDialogConfig<any>,
    dialogType: string | undefined = undefined
  ): MatDialogRef<any, any> {
    this.currentDialog = this.dialog.open(componentOrTemplateRef, config);
    this.store.dispatch(fromDialogAction.OpenDialog({ dialogType }));
    if (this.currentDialog.afterClosed) {
      this.currentDialog
        .afterClosed()
        .subscribe(() => this.store.dispatch(fromDialogAction.DialogClosed()));
    }
    return this.currentDialog;
  }

  closeDialog(): void {
    this.dialog.closeAll();
    if (this.currentDialog) {
      this.currentDialog.close();
      this.currentDialog = undefined;
    }
  }
}
