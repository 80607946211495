<div id="app">
  <div class="mat-typography flex flex-row" data-test="snackbar">
    <span class="flex flex-row flex-grow items-center content-center">
      <i class="fa-light fa-circle-xmark"></i>

      <div class="ml-3" data-test="snackbar-message">
        <p><strong>{{ 'errorTexts.errorSnackbar.title' | translate }}</strong></p>
        <div *ngIf="generateTextObservable(data.message) | async as message">
          <div class="flex flex-row">
            <div>
              <strong>{{ 'errorTexts.errorSnackbar.error' | translate }} </strong> <br />
              {{ message }}
            </div>
          </div>
          <p>{{ 'errorTexts.errorSnackbar.mention' | translate }} <a [href]="'mailto:customerservice@portbase.com?subject=IAMconnected error: '+message"
            class="font-bold">customer service.</a>
        </p>
        </div>
      </div>

    </span>

    <div class="grow-0 top-0 w-11">
      <button class="mat-button" (click)="close()" data-test="snackbar-button-close">
        <i class="fa-light fa-xmark mr-0 fa-lg"></i>
      </button>
    </div>
  </div>
</div>
