import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IamRestService } from 'projects/iam/src/app/core/services/iam-rest.service';

// RXJS
import { Observable } from 'rxjs';

// NGRX
import { Store } from '@ngrx/store';
import { UserBusinessProfile } from '../../../models/user/user-business-profile.interface';

@Injectable({
  providedIn: 'root',
})
export class PoliciesService extends IamRestService {
  constructor(private _http: HttpClient, private _store: Store) {
    super(_http, _store);
  }

  coupleWithOrganization(
    organizationId: string,
    businessInfo: UserBusinessProfile
  ): Observable<unknown> {
    const url = `${this.baseUrl}/policies/:userId/${organizationId}`;
    return this.post(url, businessInfo);
  }

  decoupleFromOrganization(organizationId: string): Observable<unknown> {
    const url = `${this.baseUrl}/policies/:userId/${organizationId}`;
    return this.delete(url);
  }
}
