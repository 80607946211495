import { AppEffects } from './app.effects';
import { OrganizationsEffects } from './organizations.effects';
import { ProfileEffects } from './profile.effects';
import { ReferencesEffects } from './references.effects';
import { PortalEffects } from './portal.effects';
import { ChangeRequestsEffects } from './change-requests.effects';
import { RumEffects } from './rum.effects';
import { OrganizationRolesEffects } from './organization-roles.effects';
import { QuarterlyReviewEffects } from './quarterly-review.effects';

export const effects = [
  AppEffects,
  OrganizationsEffects,
  ProfileEffects,
  ReferencesEffects,
  PortalEffects,
  ChangeRequestsEffects,
  RumEffects,
  OrganizationRolesEffects,
  QuarterlyReviewEffects,
];

export * from './app.effects';
export * from './organizations.effects';
export * from './profile.effects';
export * from './portal.effects';
export * from './change-requests.effects';
export * from './rum.effects';
export * from './organization-roles.effects';
export * from './quarterly-review.effects';