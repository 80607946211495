import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { portalQuery } from '../../store/selectors/portal.selectors';
import { Observable } from 'rxjs';

@Injectable()
export class FeaturetoggleGuard {
  constructor(private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const featureToggle = route.data['featureToggle'] as string;
    return this.store.select(portalQuery.getFeatureToggle(featureToggle));
  }
}
