import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'iam-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
  providers: [CookieService],
})
export class CookieBannerComponent {
  showCookieBanner = false;

  constructor(private readonly cookieService: CookieService) {
    this.showCookieBanner = !this.cookieService.check('showed-cookie-banner');

    // automatically hide the banner after 30sec
    this.showCookieBanner ?? setTimeout(() => this.close(), 30000);
  }

  close(): void {
    this.showCookieBanner = false;
    this.cookieService.set('showed-cookie-banner', 'true', 365);
  }
}
