import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs';
import { OpenRequestTypes } from './models/open-requests/open-request-types.model';
import { OpenRequestStatus } from './models/open-requests/open-requests-status.model';
import { portalQuery } from './store/selectors/portal.selectors';
import { BeamerService } from './core/services/beamer.service';

export function checkOpenRequest(store: Store, router: Router): Function {
  return () =>
    new Promise(resolve => {
      // If the user has a new change request with the type NEW_ORGANIZATION, redirect the user to the open requests page
      store
        .select(portalQuery.getPortalInfo)
        .pipe(
          filter(() => !router.url.includes('checkedid')),
          filter(
            portalInfo =>
              Boolean(portalInfo.changeRequest) &&
              portalInfo.changeRequest.req_type ===
                OpenRequestTypes.NEW_ORGANIZATION && (portalInfo.changeRequest.status !== OpenRequestStatus.NEW && portalInfo.changeRequest.status !== OpenRequestStatus.ACCEPTED )
          ),
          take(1)
        )
        .subscribe(() => {
          router.navigate(['open-requests']);
        });

      resolve(true);
    });
}

export function initApplication(store: Store, beamerService: BeamerService): Function {
  return () =>
    new Promise(resolve => {
      store
        .select(portalQuery.getPortalInfo)
        .pipe(
          filter(portalInfo => Boolean(portalInfo?.profile?.sub)),
          take(1)
        )
        .subscribe((portalInfo) => {
          if(portalInfo?.profile?.sub){
            beamerService.updateUserId(portalInfo.profile.sub);
          }
        });

      resolve(true);
    });
}
