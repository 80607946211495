import { Component, Pipe, PipeTransform } from '@angular/core';

// NGRX
import { select, Store } from '@ngrx/store';
import { SelectPortalOrganization } from '../../../store/actions/portal.actions';
import { portalQuery } from '../../../store/selectors/portal.selectors';
import { getRouterUrl } from '../../../store/selectors/router.selectors';
import { PortalState } from '../../../store/reducers/portal.reducer';

// RXJS
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { convertRouteToPortalPage } from '../../utils/route-to-portal-page.utils';
import { PortalOrganization } from '../../../models/portal/portal-info.interface';
import { DefaultUserGroups } from '../../../models/default-user-groups.interface';
import { OrganizationStatus } from '../../../models/organization/organization-status.interface';

@Pipe({ name: 'getOrgById' })
export class GetOrgByIdPipe implements PipeTransform {
  transform(portalOrg: PortalState, orgId: string): PortalOrganization {
    return portalOrg.organizations.find(org => org.organization_id === orgId);
  }
}

@Component({
  selector: 'iam-organization-selector',
  templateUrl: './organization-selector.component.html',
  styleUrls: ['./organization-selector.component.scss'],
  providers: [],
})
export class OrganizationSelectorComponent {
  private filterOrganizations = (org: PortalOrganization): boolean =>
    (org.member_of === DefaultUserGroups.ADMINS &&
      org.status !== OrganizationStatus.INACTIVE) ||
    (org.status === OrganizationStatus.ACTIVE &&
      org.member_of !== DefaultUserGroups.SUSPENDED &&
      org.member_of !== DefaultUserGroups.PENDING);

  private loaded$ = this.store.pipe(select(portalQuery.loadedPortal));
  private loggedIn$ = this.store.pipe(select(portalQuery.loggedInToPortal));

  portalInfo$ = this.store.pipe(
    select(portalQuery.getPortalInfo),
    map(portalInfo => ({
      ...portalInfo,
      organizations: portalInfo.organizations.filter(this.filterOrganizations),
    }))
  );

  showLoader$ = combineLatest([this.loaded$, this.loggedIn$]).pipe(
    map((loaded, loggedIn) => !Boolean(loaded) && !Boolean(loggedIn))
  );

  constructor(private store: Store<PortalState>) {}

  getOrganizationName(orgId: string): Observable<unknown> {
    return this.portalInfo$.pipe(
      map(
        item =>
          item.organizations.find(org => org.organization_id === orgId)?.name
      )
    );
  }

  changeSelectedOrganization($event: { value: string }): void {
    const organizationId = $event.value;
    this.store
      // @ts-ignore
      .pipe(take(1), select(getRouterUrl), map(convertRouteToPortalPage))
      .subscribe(portalPage =>
        this.store.dispatch(
          SelectPortalOrganization({
            organizationId,
            portalPage,
          })
        )
      );
  }
}
