import { portalQuery } from '../selectors/portal.selectors';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// RXJS
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// Services
import { PortalPages } from '../../models/portal/portal-pages';
import { ChangeRequestsService } from '../../features/organization/services/change-requests.service';
import { OpenRequestTypes } from '../../models/open-requests/open-request-types.model';
import { ShowSnackbar } from '../../libs/portbase-snackbar-components/src/lib/store/snackbar.actions';
import {
  CancelActivationCodeRequests,
  CancelActivationCodeRequestsSuccess,
  CancelChangeRequests,
  CancelChangeRequestsError,
  CancelChangeRequestsSuccess,
  CreateNewChangeRequests,
  CreateNewChangeRequestsError,
  CreateNewChangeRequestsSuccess,
  LoadChangeRequests,
  LoadChangeRequestsError,
  LoadChangeRequestsSuccess,
  ResendEmailVerification,
  ResendEmailVerificationSuccess,
  SearchChangeRequests,
  SearchChangeRequestsError,
  SearchChangeRequestsSuccess,
  SubmitActivationCodeRequests,
  SubmitActivationCodeRequestsError,
  SubmitActivationCodeRequestsSuccess,
  UpdateChangeRequestsSuccess,
  UpdateOrganizationRequests,
  UpdateOrganizationRequestsError,
  UpdateOrganizationRequestsSuccess,
  ResendIdentificationInvite,
  ResendIdentificationInviteSuccess,
  AcceptTermsAndConditions,
  AcceptTermsAndConditionsSuccess,
  AcceptTermsAndConditionsError,
  ResendActivationLink,
  ResendActivationLinkSuccess,
} from '../actions/change-requests.actions';
import { PortalInfoLoad, PortalInfoReload } from '../actions/portal.actions';
import { LoadProfile } from '../../features/profile/store/profile/profile.actions';

@Injectable()
export class ChangeRequestsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private changeRequestsService: ChangeRequestsService,
    private translateService: TranslateService
  ) {}

  LoadChangeRequests$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadChangeRequests),
        switchMap(() =>
          this.changeRequestsService.getChangeRequests().pipe(
            map(changeRequest =>
              LoadChangeRequestsSuccess({
                changeRequest,
              })
            ),
            catchError(error =>
              of(
                LoadChangeRequestsError({
                  error,
                })
              )
            )
          )
        )
      ),
    { dispatch: true }
  );

  // @ts-ignore
  CancelChangeRequests$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CancelChangeRequests),
        switchMap(({ showMessage }) =>
          this.changeRequestsService.cancelChangeRequests().pipe(
            switchMap(() => [
              CancelChangeRequestsSuccess(),
              showMessage
                ? ShowSnackbar({
                    message: this.translateService.instant('successTexts.general.cancelActivationCode'),
                    level: 'success',
                  })
                : of(''),
              LoadChangeRequests(),
            ]),
            catchError(({ error }) =>
              of(
                CancelChangeRequestsError({
                  error: error,
                })
              )
            )
          )
        )
      ),
    { dispatch: true }
  );

  CancelChangeRequestsError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CancelChangeRequestsError),
        map(({ error }) =>
          ShowSnackbar({
            message: this.translateService.instant(error.Error || 'errorTexts.general.badRequest'),
            level: 'danger',
          })
        )
      ),
    { dispatch: true }
  );

  SearchOrganizationRequests$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchChangeRequests),
        switchMap(({ request }) =>
          this.changeRequestsService.searchOrganizationRequest(request).pipe(
            switchMap(organizationRequestsSearchResults => [
              SearchChangeRequestsSuccess({
                organizationRequestsSearchResults,
              }),
            ]),
            catchError(({ error }) =>
              of(
                SearchChangeRequestsError({
                  error: error,
                })
              )
            )
          )
        )
      ),
    { dispatch: true }
  );

  CreateNewOrganizationRequests$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CreateNewChangeRequests),
        switchMap(({ request, requestType, organizationId }) =>
          this.changeRequestsService.newChangeRequests(request, requestType, organizationId).pipe(
            switchMap(changeRequest => {
              return [
                CreateNewChangeRequestsSuccess({
                  requestType,
                }),
                LoadChangeRequests(),
                ShowSnackbar({
                  message: changeRequest
                    ? this.translateService.instant('successTexts.changeRequest')
                    : this.translateService.instant('successTexts.general.title'),
                  level: 'success',
                }),
              ];
            }),
            catchError(({ error }) =>
              of(
                CreateNewChangeRequestsError({
                  error,
                })
              )
            )
          )
        )
      ),
    { dispatch: true }
  );

  ChangeUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateNewChangeRequestsSuccess),
      filter(({ requestType }) => requestType === OpenRequestTypes.CHANGE_USER_PROFILE),
      withLatestFrom(this.store.select(portalQuery.getUserId)),
      switchMap(([, userId]) => [
        PortalInfoLoad({
          // @ts-ignore
          userId,
          portalPage: PortalPages.PROFILE,
        }),
        LoadProfile(),
      ])
    )
  );

  CreateNewOrganizationRequestsError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CreateNewChangeRequestsError),
        map(({ error }) =>
          ShowSnackbar({
            message: this.translateService.instant(error.Error || 'errorTexts.general.badRequest'),
            level: 'danger',
          })
        )
      ),
    { dispatch: true }
  );

  UpdateOrganizationRequests$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UpdateOrganizationRequests),
        switchMap(({ request, requestType }) =>
          this.changeRequestsService.updateOrganizationRequests(request, requestType).pipe(
            switchMap(request => [
              UpdateOrganizationRequestsSuccess({
                requestType,
                request,
              }),
            ]),
            catchError(({ error }) =>
              of(
                UpdateOrganizationRequestsError({
                  error,
                  requestType,
                })
              )
            )
          )
        )
      ),
    { dispatch: true }
  );

  UpdateChangeRequestsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UpdateChangeRequestsSuccess),
        map(() =>
          ShowSnackbar({
            message: this.translateService.instant('successTexts.general.title'),
            level: 'success',
          })
        )
      ),
    { dispatch: true }
  );

  UpdateOrganizationRequestsError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UpdateOrganizationRequestsError),
        switchMap(({ error, requestType }) =>
          requestType !== OpenRequestTypes.UPDATE_ORGANIZATION_DETAILS
            ? [
                ShowSnackbar({
                  message: this.translateService.instant(error.Error || 'errorTexts.general.badRequest'),
                  level: 'danger',
                  options: {
                    duration: 20000,
                  },
                }),
                PortalInfoReload(),
                LoadChangeRequests(),
              ]
            : [PortalInfoReload(), LoadChangeRequests()]
        )
      ),
    { dispatch: true }
  );

  SubmitActivationCodeRequests$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SubmitActivationCodeRequests),
        switchMap(action =>
          this.changeRequestsService.submitActivationCode(action.request).pipe(
            switchMap(() => [
              SubmitActivationCodeRequestsSuccess(),
              PortalInfoReload(),
              ShowSnackbar({
                message: this.translateService.instant('successTexts.general.title'),
                level: 'success',
                options: { duration: 60000 },
              }),
              LoadChangeRequests(),
            ]),
            catchError(({ error }) =>
              of(
                SubmitActivationCodeRequestsError({
                  error: error,
                })
              )
            )
          )
        )
      ),
    { dispatch: true }
  );

  SubmitActivationCodeRequestsError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SubmitActivationCodeRequestsError),
        map(({ error }) =>
          ShowSnackbar({
            message: this.translateService.instant(error.Error || 'errorTexts.general.badRequest'),
            level: 'danger',
          })
        )
      ),
    { dispatch: true }
  );

  CancelActivationCodeRequests$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CancelActivationCodeRequests),
        switchMap(() =>
          this.changeRequestsService.cancelChangeRequests().pipe(
            switchMap(() => [
              CancelActivationCodeRequestsSuccess(),
              ShowSnackbar({
                message: this.translateService.instant('successTexts.general.cancelActivationCode'),
                level: 'success',
                options: { duration: 60000 },
              }),
              LoadChangeRequests(),
            ]),
            catchError(({ error }) =>
              of(
                CancelChangeRequestsError({
                  error: error,
                })
              )
            )
          )
        )
      ),
    { dispatch: true }
  );

  CancelActivationCodeRequestsError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CancelChangeRequestsError),
        map(({ error }) =>
          ShowSnackbar({
            message: this.translateService.instant(error.Error || 'errorTexts.general.badRequest'),
            level: 'danger',
          })
        )
      ),
    { dispatch: true }
  );

  ResendEmailVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResendEmailVerification),
      switchMap(() =>
        this.changeRequestsService.resendEmailVerification().pipe(
          switchMap(() => [
            ResendEmailVerificationSuccess(),
            ShowSnackbar({
              message: this.translateService.instant('successTexts.general.title'),
              level: 'success',
            }),
          ]),
          catchError(error =>
            of(
              ShowSnackbar({
                message: this.translateService.instant(error.Error || 'errorTexts.general.badRequest'),
                level: 'danger',
              })
            )
          )
        )
      )
    )
  );

  AcceptTermsAndConditions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AcceptTermsAndConditions),
      switchMap(action =>
        this.changeRequestsService
          .acceptTermsAndConditions(action.code, action.termsAndConditions)
          .pipe(switchMap(() => [AcceptTermsAndConditionsSuccess({ code: action.code })]))
      ),
      catchError(error => of(AcceptTermsAndConditionsError({ error })))
    )
  );

  ResendIdentificationInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResendIdentificationInvite),
      switchMap(({ repr_email_address }) =>
        this.changeRequestsService.resendIdentificationInvite().pipe(
          switchMap(() => [
            ResendIdentificationInviteSuccess(),
            ShowSnackbar({
              message: this.translateService.instant('successTexts.resendIdentification') + repr_email_address,
              level: 'success',
            }),
          ]),
          catchError(error =>
            of(
              ShowSnackbar({
                message: this.translateService.instant(error.Error || 'errorTexts.general.badRequest'),
                level: 'danger',
              })
            )
          )
        )
      )
    )
  );

  ResendActivationLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResendActivationLink),
      switchMap(({ repr_email_address }) =>
        this.changeRequestsService.resendActivationlink().pipe(
          switchMap(() => [
            ResendActivationLinkSuccess(),
            ShowSnackbar({
              message: this.translateService.instant('successTexts.resendIdentification') + repr_email_address,
              level: 'success',
            }),
          ]),
          catchError(error =>
            of(
              ShowSnackbar({
                message: this.translateService.instant(error.Error || 'errorTexts.general.badRequest'),
                level: 'danger',
              })
            )
          )
        )
      )
    )
  );
}
