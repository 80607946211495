import { NgModule } from '@angular/core';

import { CdkDetailRowDirective } from './cdk-detail-row/cdk-detail-row.directive';
import { FeatureToggleDirective } from './feature-toggle/feature-toggle.directive';
import { IfInRoleDirective } from './ifinrole/ifinrole.directive';
import { BackButtonDirective } from './back-button/back-button.directive';


@NgModule({
  declarations: [
    FeatureToggleDirective,
    IfInRoleDirective,
    CdkDetailRowDirective,
    BackButtonDirective
  ],
  providers: [{ provide: Window, useValue: window }],
  exports: [
    FeatureToggleDirective,
    IfInRoleDirective,
    CdkDetailRowDirective,
    BackButtonDirective
  ],
})
export class DirectivesModule {}
