<div class="bg-background justify-center flex-grow flex-row"
  [ngClass]="(showNotification$ | async) && ((currentQuarterlyReviewStatus$ | async) === 'not_completed' || (currentQuarterlyReviewStatus$ | async) === 'new') ? 'flex' : 'hidden'"
  *ngIf="(showNotification$ | async) && ((currentQuarterlyReviewStatus$ | async) === 'not_completed' || (currentQuarterlyReviewStatus$ | async) === 'new')"
  data-test="quarterly-review-notification">
  <div class="w-9/12 ">
    <div class="mt-4" [ngClass]="selectedWidth">
      <iam-notice-bar type="info_white">
        <div *ngIf="(currentQuarterlyReviewStatus$ | async) === 'not_completed'; else openReview">
          <div class="flex flex-row py-2">
            <div class="flex-grow">
              <p class="font-bold m-0">{{ 'userManagement.periodicValidation.notificationBar.titleOpen' | translate }}
              </p>
              <p class="m-0">{{ 'userManagement.periodicValidation.notificationBar.descriptionOpen' | translate }}</p>
            </div>
            <div class="flex-grow-0 flex items-center">
              <p-button 
                routerLink="/usermanagement/quarterly-review"
                [label]="'userManagement.periodicValidation.notificationBar.buttonOpen' | translate"
                data-test="start-control-button"
              ></p-button>
            </div>
          </div>
        </div>
        <ng-template #openReview>
          <div class="flex flex-row py-2">
            <div class="flex-grow">
              <p class="font-bold m-0">{{ 'userManagement.periodicValidation.notificationBar.title' | translate }}</p>
              <p class="m-0">{{ 'userManagement.periodicValidation.notificationBar.description' | translate : { count:
                (quarterlyReviewsNotification$ | async) } }}</p>
            </div>
            <div class="flex-grow-0 flex items-center">
              <p-button 
                routerLink="/usermanagement/quarterly-review"
                [label]="'userManagement.periodicValidation.notificationBar.button' | translate "
                data-test="start-control-button"
              ></p-button>
            </div>
          </div>
        </ng-template>

      </iam-notice-bar>
    </div>
  </div>
</div>