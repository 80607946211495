import { createAction, props } from '@ngrx/store';
import {
  OrganizationRequestSearch,
  OrganizationRequestSearchResults,
} from '../../models/organization/organization-request-search-results-interface';
import { ServiceMgtRequest } from '../../models/servicemgt/servicemgt-request.interface';
import {
  ActivationCodeRequest,
  NewOrganizationRequest,
  OrganizationRequest,
  PutOrganizationRequest,
} from '../../models/organization/organization-request.interface';
import { UserBusinessProfile } from '../../models/user/user-business-profile.interface';
import { UserProfileRequest } from '../../models/user/userprofile.interface';
import { ErrorMessage } from '../../models/error-message.interface';
import { OpenRequestTypes } from '../../models/open-requests/open-request-types.model';
import { HttpErrorResponse } from '@angular/common/http';
import { TermsAndConditions } from '../../features/home/models/terms-and-conditions.interface';

export const CancelChangeRequests = createAction(
  '[Change requests] cancel Change requests',
  (showMessage = true) => ({ showMessage })
);

export const CancelChangeRequestsSuccess = createAction(
  '[Change requests] cancel Change requests success'
);

export const CancelChangeRequestsError = createAction(
  '[Change requests] cancel Change requests failed',
  props<{ error: ErrorMessage }>()
);

export const LoadChangeRequests = createAction(
  '[Change requests] Load Change requests'
);

export const LoadChangeRequestsSuccess = createAction(
  '[Change requests] Load Change requests success',
  props<{ changeRequest: Partial<OrganizationRequest> }>()
);

export const LoadChangeRequestsError = createAction(
  '[Change requests] Load change requests error',
  props<{ error: ErrorMessage }>()
);

export const SearchChangeRequests = createAction(
  '[Change requests] Search change requests',
  props<{ request: OrganizationRequestSearch }>()
);

export const SearchChangeRequestsSuccess = createAction(
  '[Change requests] Search change requests success',
  props<{
    organizationRequestsSearchResults: OrganizationRequestSearchResults[];
  }>()
);

export const SearchChangeRequestsError = createAction(
  '[Change requests] Search change requests error',
  props<{ error: ErrorMessage }>()
);

export const ResetSearchResultsChangeRequests = createAction(
  '[Change requests] Reset search results change requests'
);

export const CreateNewChangeRequests = createAction(
  '[Change requests] Create new change requests',
  props<{
    request:
      | NewOrganizationRequest
      | ServiceMgtRequest
      | UserBusinessProfile
      | Partial<UserProfileRequest>;
    requestType: OpenRequestTypes;
    organizationId?: string;
    successMessage?: string;
  }>()
);

export const CreateNewChangeRequestsSuccess = createAction(
  '[Change requests] Create new change requests success',
  props<{
    requestType: OpenRequestTypes;
  }>()
);

export const CreateNewChangeRequestsError = createAction(
  '[Change requests] Create new organization requests error',
  props<{ error: ErrorMessage }>()
);

export const UpdateOrganizationRequests = createAction(
  '[Change requests] Update organization requests',
  props<{
    request: Partial<PutOrganizationRequest>;
    requestType: OpenRequestTypes;
  }>()
);

export const UpdateOrganizationRequestsSuccess = createAction(
  '[Change requests] Update organization requests success',
  props<{
    requestType: OpenRequestTypes;
    request: Partial<OrganizationRequest>;
  }>()
);

export const UpdateChangeRequestsSuccess = createAction(
  '[Change requests] Update change requests success',
  props<{ organizationRequests: OrganizationRequest }>()
);
export const UpdateOrganizationRequestsError = createAction(
  '[Change requests] Update organization requests error',
  props<{ error: ErrorMessage; requestType: OpenRequestTypes }>()
);

export const SubmitActivationCodeRequests = createAction(
  '[Change requests] Submit activation code requests',
  props<{ request: ActivationCodeRequest }>()
);

export const SubmitActivationCodeRequestsSuccess = createAction(
  '[Change requests] Submit activation code requests success'
);

export const SubmitActivationCodeRequestsError = createAction(
  '[Change requests] Submit activation code requests error',
  props<{ error: ErrorMessage }>()
);

export const CancelActivationCodeRequests = createAction(
  '[Change requests] Cancel activation code requests'
);

export const CancelActivationCodeRequestsSuccess = createAction(
  '[Change requests] Cancel activation code requests success'
);

export const CancelActivationCodeRequestsError = createAction(
  '[Change requests] Cancel activation code requests error',
  props<{ error: ErrorMessage }>()
);

export const ResendEmailVerification = createAction(
  '[Change requests] Resend Email Verification'
);

export const ResendEmailVerificationSuccess = createAction(
  '[Change requests] Resend Email Verification Success'
);

export const ResendEmailVerificationError = createAction(
  '[Change requests] Resend Email Verification Error',
  props<{ error: ErrorMessage }>()
);

export const ResetErrorMessage = createAction(
  '[Change requests] Reset Error Message'
);

export const ResendActivationLink = createAction(
  '[Change requests] Resend Activation Link',
  props<{repr_email_address : string}>()
);

export const ResendActivationLinkSuccess = createAction(
  '[Change requests] Resend Activation Link success'
);

export const ResendActivationLinkError = createAction(
  '[Change requests] Resend Activation Link error'
);

export const ResendIdentificationInvite = createAction(
  '[Change requests] Resend Identification invite',
  props<{repr_email_address : string}>()
);

export const ResendIdentificationInviteSuccess = createAction(
  '[Change requests] Resend Identification invite success'
);

export const ResendIdentificationInviteError = createAction(
  '[Change requests] Resend Identification invite error'
);

export const AcceptTermsAndConditions = createAction(
  '[Change requests] Accept Terms And Conditions',
  props<{code : string, termsAndConditions: TermsAndConditions}>()
);

export const AcceptTermsAndConditionsSuccess = createAction(
  '[Change requests] Accept Terms And Conditions success',
  props<{code : string}>()
);

export const AcceptTermsAndConditionsError = createAction(
  '[Change requests] Accept Terms And Conditions error',
  props<{ error: HttpErrorResponse }>()
);
