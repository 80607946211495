import { Action, createReducer, on } from '@ngrx/store';
import {
  AuthSignatory,
  Organization,
} from '../../models/organization/organization.interface';
import { ErrorMessage } from '../../models/error-message.interface';
import {
  OrganizationAdded,
  OrganizationDeleted,
  OrganizationsLoaded,
  OrganizationsLoadError,
  OrganizationUpdated,
  SecurityOrganizationUpdated,
  SelectedOrganizationLoaded,
} from '../actions/organizations.actions';
import { ResetSelectedOrganization } from '../../features/organization/store/organizations.actions';

/**
 * Interface for the organizations data used in
 *  - OrganizationsState, and
 *  - OrganizationsReducer
 *
 *  Note: replace if already defined in another module
 */

export const ORGANIZATIONS_FEATURE_KEY = 'organizations';

export interface OrganizationsState {
  list: Organization[];
  selectedId?: string | number;
  selectedOrganization: Organization | undefined;
  loaded: boolean;
  selectedOrgLoaded: boolean;
  error?: ErrorMessage;
  organizationRoles: string[];
}

export interface OrganizationsPartialState {
  readonly [ORGANIZATIONS_FEATURE_KEY]: OrganizationsState;
}

export const initialState: OrganizationsState = {
  list: [],
  loaded: false,
  selectedOrganization: undefined,
  selectedOrgLoaded: false,
  organizationRoles: [],
};

const organizationsReducer = createReducer(
  initialState,
  on(OrganizationsLoaded, (state, { payload }) => ({
    ...state,
    list: payload.map(organization => ({
      ...organization,
      auth_signatory: formatAuthSignatory(organization),
    })),
    loaded: true,
    loading: false,
  })),
  on(OrganizationsLoadError, (state, { error, actionSourceType }) => ({
    ...state,
    loading: false,
    error,
    actionSourceType,
  })),
  on(SelectedOrganizationLoaded, (state, { organization }) => ({
    ...state,
    selectedId: organization.id,
    selectedOrganization: {
      ...organization,
      auth_signatory: formatAuthSignatory(organization),
    },
    selectedOrgLoaded: true,
  })),
  on(ResetSelectedOrganization, state => ({
    ...state,
    selectedOrganization: undefined,
    organizationRequests: undefined,
  })),
  on(OrganizationAdded, (state, { id }) => ({
    ...state,
    id: id,
  })),
  on(OrganizationUpdated, (state, { organization }) => ({
    ...state,
    selectedOrganization: organization,
  })),
  on(SecurityOrganizationUpdated, (state, { organization }) => ({
    ...state,
    selectedOrganization: organization,
  })),
  on(OrganizationDeleted, (state, { id }) => ({
    ...state,
    id: id,
  }))
);

function formatAuthSignatory(organization: Organization): AuthSignatory {
  if (typeof organization.auth_signatory === 'string') {
    const authSignatory = {
      email_address: organization.email,
      date_of_birth: organization.auth_signatory_birth_date,
      name: organization.auth_signatory,
      phone_number: organization.telephone,
    };
    deleteOrganizationKeyIfExists(organization, [
      'email',
      'auth_signatory_birth_date',
      'telephone',
    ]);
    return authSignatory;
  } else {
    return organization.auth_signatory as AuthSignatory;
  }
}

function deleteOrganizationKeyIfExists(
  organization: Organization,
  keys: string[]
): void {
  keys.forEach(key => {
    if (key in organization) {
      if (organization[key] && !organization[key].length)
        delete organization[key];
    }
  });
}

export function reducer(
  state: OrganizationsState | undefined,
  action: Action
): OrganizationsState {
  return organizationsReducer(state, action);
}
