import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'iam-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit {
  constructor(
    private toasterService: ToastrService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (!this.cookiesEnabled()) {
      this.toasterService.error(
        this.translateService.instant('errorTexts.cookiesDisabled.body'),
        this.translateService.instant('errorTexts.cookiesDisabled.title'),
        {
          disableTimeOut: true,
        }
      );
    }
  }

  cookiesEnabled(): boolean {
    let cookieEnabled = !!navigator.cookieEnabled;

    if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
      document.cookie = 'testcookie=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
    return cookieEnabled;
  }
}
