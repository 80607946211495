import { 
    UpdateQuarterlyReview, 
    UpdateQuarterlyReviewSuccess,
    UpdateQuarterlyReviewError, 
    GetQuarterlyReview, 
    GetQuarterlyReviewSuccess, 
    GetQuarterlyReviewError,
    ResetQuarterlyReviewMembers
} from '../actions/quarterly-review.actions';
import { Action, createReducer, on } from '@ngrx/store';

import { QuarterlyReview } from '../../models/quarterly-review/quarterly-review.interface';

export interface QuarterlyReviewState extends QuarterlyReview {
    loaded: boolean,
    feedback: string,
    error: string,
    loading: boolean;
}

export const initialState: QuarterlyReviewState = {
    periodic_validation_records: [],
    members_to_validate: [],
    loaded: false,
    feedback: '',
    error: '',
    loading: false
}

const quarterlyReviewReducers = createReducer(
    initialState,
    on(UpdateQuarterlyReview, (state) => ({
        ...state,
        feedback: '',
        error: '',
        loading: true
    })),
    on(UpdateQuarterlyReviewSuccess, (state, payload) => ({
        ...state,
        ...payload,
        feedback: '',
        error: '',
        loading: false
    })),
    on(UpdateQuarterlyReviewError, (state, { error }) => ({
        ...state,
        feedback: '',
        error: error,
        loading: false,
    })),
    on(GetQuarterlyReview, (state) => ({
        ...state,
        loaded: false,
        error: '',
        loading: true
    })),
    on(GetQuarterlyReviewSuccess, (state, payload) => ({
        ...state,
        periodic_validation_records: payload.periodic_validation_records,
        members_to_validate: payload.members_to_validate,
        loaded: true,
        loading: false
    })),
    on(GetQuarterlyReviewError, (state) => ({
        ...state,
        error: 'payload',
        loaded: true,
        loading: false,
        periodic_validation_records: [],
        members_to_validate: [],
    })),
    on(ResetQuarterlyReviewMembers, (state) => ({
        ...state,
        periodic_validation_records: [],
        members_to_validate: [],
    })),

    
)

export function reducer(
    state: QuarterlyReviewState | undefined,
    action: Action
  ): QuarterlyReviewState {
    return quarterlyReviewReducers(state, action);
  }
  