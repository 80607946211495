import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

// NGRX
import { ActionsSubject, select, Store } from '@ngrx/store';
import { CheckLogin } from 'projects/iam/src/app/auth/store/actions/auth.actions';

// RXJS
import { combineLatest, Observable } from 'rxjs';
import {
  filter,
  map,
  pairwise,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

// UTILS
import { convertRouteToPortalPage } from './core/utils/route-to-portal-page.utils';
import { ofType } from '@ngrx/effects';
import { filterByRoutesNotAllowed } from './core/utils/filter-by-route.utils';
import { Location } from '@angular/common';
import { stripPortalUri } from './core/utils/url.utils';
import { DestroyService } from './libs/shared/services/destroy.service';
import { portalQuery } from './store/selectors/portal.selectors';
import {
  PortalInfoLoad,
  PortalInfoLoaded,
} from './store/actions/portal.actions';
import { appQuery } from './store/selectors/app.selectors';
import { filterChangeRequestByStatusNew } from './features/open-requests/utils/change-requests.utils';
import { State } from './store/reducers/state';

@Component({
  selector: 'iam-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DestroyService],
})
export class AppComponent implements OnInit {
  private routerEvent$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd)
  );

  private routeWithHistory$ = this.router.events.pipe(
    // @ts-ignore
    filter((evt: RoutesRecognized) => evt instanceof RoutesRecognized),
    pairwise()
  );

  private userId$: Observable<string> = this.store.select(
    portalQuery.getUserId
  );

  private currentUserRoles$ = this.store.select(
    portalQuery.getCurrentOrganizationRole
  );

  loggedIn$ = this.store.select(portalQuery.loggedInToPortal);

  constructor(
    private unsubscribe$: DestroyService,
    private translate: TranslateService,
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    private actionSubject: ActionsSubject,
    private location: Location
  ) {
    this.store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(appQuery.getSelectedLanguage),
        filter(selectedLanguage => !!selectedLanguage)
      )
      .subscribe(selectedLanguage => {
        this.translate.use(selectedLanguage);
        this.translate.currentLang = selectedLanguage;
      });

    combineLatest([this.currentUserRoles$, this.routerEvent$])
      .pipe(
        takeUntil(this.unsubscribe$),
        // There's a route change or change in user roles
        map(([currentUserRoles]) => {
          let route = this.route;
          while (!this.getAllowedFor(route) && route.firstChild) {
            route = route.firstChild;
          }
          // We are no longer interested in the routerEvent, but rather the ActivatedRoute
          // So we pass that on with the currentUserRoles
          return { route, currentUserRoles };
        }),
        filter(({ route }) => route.outlet === 'primary'),
        // Keep passing the route and the currentUserRoles
        map(({ route, currentUserRoles }) => ({
          permittedRoles: this.getAllowedFor(route),
          currentUserRoles,
        })),
        filter(({ permittedRoles }) => permittedRoles !== undefined)
      )
      .subscribe(({ permittedRoles, currentUserRoles }) => {
        // Check if this user has the user roles associated with the route
        const hasPermittedRole = currentUserRoles.some(
          role => permittedRoles.indexOf(role) >= 0
        );
        // If not, route to home
        if (!hasPermittedRole) {
          this.router.navigate(['/']);
        }
      });

    this.actionSubject
      .pipe(
        ofType(PortalInfoLoaded),
        take(1),
        switchMap(() =>
          this.store.pipe(
            select(portalQuery.getPortalInfo),
            take(1),
            filter(() => {
              const redirectUri = localStorage.getItem('redirect_uri');
              if (redirectUri) {
                localStorage.removeItem('redirect_uri');
                this.router.navigate([redirectUri]);
                return false;
              }
              return true;
            }),
            tap(() => {
              if (window.location.href.includes('?')) {
                this.location.go(
                  stripPortalUri(window.location.href.split('?')[0])
                );
              }
            }),
          )
        )
      )
      .subscribe();
  }

  private getAllowedFor(route: ActivatedRoute): string[] | undefined {
    const routeData = route.snapshot.data;
    return routeData.allowedFor ?? undefined;
  }

  ngOnInit(): void {
    // Only dispatch the 'CheckLogin' action when we're not in the /auth module
    if (window.location.pathname.indexOf('/auth/') === -1) {
      this.store.dispatch(CheckLogin());
    }

    combineLatest([this.routeWithHistory$, this.userId$, this.loggedIn$])
      .pipe(
        filter(([events]) => filterByRoutesNotAllowed(events)),
        filter(([, , loggedIn]) => Boolean(loggedIn)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([events, userId]) => [
        this.store.dispatch(
          PortalInfoLoad({
            userId,
            portalPage: convertRouteToPortalPage(events[1].urlAfterRedirects),
          })
        ),
      ]);
  }
}
