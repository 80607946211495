<div id="app">
  <div class="mat-typography w-full flex flex-row items-start justify-between" data-test="snackbar">
    <span class="flex flex-row flex-grow items-start content-center">
      <i *ngIf="data.showIcon" class="fa-light fa-lg mt-3" [ngClass]="icon"></i>

      <div class="px-4 text-base leading-6 flex-1">
        <span *ngIf="data?.title" data-test="snackbar-title" class="block font-bold">{{data?.title}}</span>
        <span data-test="snackbar-message">{{generateTextObservable(data?.message) | async}}</span>
      </div>
    </span>
    <p-button
      icon="fa-light fa-xmark"
      [text]="true"
      (click)="close()"
      data-test="snackbar-button-close"
    ></p-button>
  </div>
</div>
