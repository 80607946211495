import { createAction, props } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SnackbarOptions } from '../models/snackbar-options.model';
import { SnackBarLevels } from '../models/snackbar-level-type.enum';

export const ShowSnackbar = createAction(
  '[Snackbar] Show snackbar',
  props<{
    title?: Observable<string> | string;
    message?: Observable<string> | string;
    level?: SnackBarLevels;
    options?: SnackbarOptions;
  }>()
);

export const SnackbarClosed = createAction('[Snackbar] Snackbar closed');
