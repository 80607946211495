import { Inject, Injectable } from '@angular/core';

import { SnackbarComponent } from '../components/snackbar/snackbar.component';
import { ErrorSnackbarComponent } from '../components/error-snackbar/error-snackbar.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { SNACKBAR_OPTIONS } from './../snackbar-options.token';
import {
  defaultOptions,
  SnackbarOptions,
} from '../models/snackbar-options.model';
import { SnackbarMessage } from '../models/snackbar-message.model';
import { SnackbarLevelType } from '../models/snackbar-level-type.enum';

@Injectable()
export class SnackbarService {
  snackbarConfig: MatSnackBarConfig;

  constructor(
    private snackBar: MatSnackBar,
    @Inject(SNACKBAR_OPTIONS) public options: SnackbarOptions
  ) {
    this.snackbarConfig = new MatSnackBarConfig();
    this.snackbarConfig.data = {};

    const mergedOptions = { ...defaultOptions, ...options };

    // Our SnackbarOptions options are partially cloned from MatSnackBarConfig
    // If we encounter a value in the SnackbarOptions that also exists in MatSnackBarConfig we overwrite it
    // Else we add it to the 'data' object of MatSnackBarConfig so it gets passed to the SnackbarComponent
    for (const [key, value] of Object.entries(mergedOptions)) {
      if (key in this.snackbarConfig) {
        this.snackbarConfig[key] = value;
      } else {
        this.snackbarConfig.data[key] = value;
      }
    }
  }

  show(data: SnackbarMessage, options?: SnackbarOptions): void {
    const mergedOptions = { ...this.snackbarConfig, ...options };

    // SnackbarOptions.panelClass is either string or string[]
    // If string[]: we need to push the level-class into the array
    // If string: we need to convert it to string[] and push the level-class + existing value
    // Else: we can set it as the level-class
    const notificationType = data.level || SnackbarLevelType.INFO;
    if (Array.isArray(mergedOptions.panelClass)) {
      mergedOptions.panelClass.push(notificationType);
    } else if (typeof mergedOptions.panelClass === 'string') {
      mergedOptions.panelClass = [mergedOptions.panelClass, notificationType];
    } else {
      mergedOptions.panelClass = notificationType;
    }

    mergedOptions.data = { ...mergedOptions.data, ...data };

    if (mergedOptions.data.level === 'error') {
      mergedOptions.duration = 10000000;
    }

    const comp =
      mergedOptions.data.level === SnackbarLevelType.ERROR
        ? ErrorSnackbarComponent
        : SnackbarComponent;

    //@ts-ignore
    this.snackBar.openFromComponent(comp, mergedOptions);
  }

  close(): void {
    this.snackBar.dismiss();
  }
}
