import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '../../models/error-message.interface';

export const AppUpdateLanguage = createAction(
  '[App] Update language',
  props<{ language: string }>()
);

export const AppLanguageUpdated = createAction(
  '[App] Language updated',
  props<{ language: string }>()
);

export const AppHttpRequestStarted = createAction('[App] Http request started');

export const AppHttpRequestFinished = createAction(
  '[App] Http request finished'
);

export const AppError = createAction(
  '[App] Error',
  props<{ error: ErrorMessage }>()
);
