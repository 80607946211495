import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, filter, map, startWith } from 'rxjs';
import { quarterlyReviewConfig } from './quarterly-review-config.const';

// Ngrx
import { Store } from '@ngrx/store';
import { portalQuery } from 'projects/iam/src/app/store/selectors/portal.selectors';
import { QuarterlyReviewStatus } from '../../../models/portal/portal-info.interface';


@Component({
  selector: 'iam-quarterly-review-notification',
  templateUrl: './quarterly-review-notification.component.html',
  styleUrls: ['./quarterly-review-notification.component.scss']
})
export class QuarterlyReviewNotificationComponent implements OnInit {

  showNotification$: Observable<boolean>;
  currentQuarterlyReviewStatus$ = this.store.select(portalQuery.currentQuarterlyReviewStatus);
  quarterlyReviewsNotification$ = this.store.select(portalQuery.getQuarterlyReviewNotifications);
  quarterlyReviewConfig = quarterlyReviewConfig;
  selectedWidth = '';
  quarterlyReviewStatus: QuarterlyReviewStatus;

  constructor(private router: Router, private store: Store){

  }
  ngOnInit(): void {
    this.showNotification$ = this.router.events.pipe(
      filter(events => events instanceof NavigationEnd),
      startWith(this.router),
      map(() => {
        const config = this.quarterlyReviewConfig.find(page => page.url === this.router.url);
        this.selectedWidth = config?.width ?? 'hidden';
        return Boolean(config);
      }),
    );
  }
}

