import { createAction, props } from '@ngrx/store';
import { PortalPages } from '../../models/portal/portal-pages';
import { UserBusinessProfile } from '../../models/user/user-business-profile.interface';
import { Organization } from '../../models/organization/organization.interface';
import { ErrorMessage } from '../../models/error-message.interface';

export const LoadOrganizations = createAction(
  '[Organizations] Load organizations'
);

export const OrganizationsLoaded = createAction(
  '[Organizations] Organizations loaded',
  props<{ payload: Organization[] }>()
);

export const OrganizationsLoadError = createAction(
  '[Organizations] Organizations load error',
  props<{ error: ErrorMessage; actionSourceType: string }>()
);

export const LoadCurrentOrganization = createAction(
  '[Organizations] Load current organization'
);

export const LoadSelectedOrganization = createAction(
  '[Organizations] Load selected organization',
  props<{ id: string }>()
);

export const SelectedOrganizationLoaded = createAction(
  '[Organizations] Selected organization loaded',
  props<{ organization: Organization }>()
);

export const UpdateOrganization = createAction(
  '[Organizations] Update organization',
  props<{ organization: Partial<Organization> }>()
);

export const UpdateSecurityOrganization = createAction(
  '[Organizations] Update Security organization',
  props<{ organization: Partial<Organization> }>()
);

export const SecurityOrganizationUpdated = createAction(
  '[Organizations] Security organization updated',
  props<{ organization: Organization }>()
);

export const OrganizationUpdated = createAction(
  '[Organizations] Organization updated',
  props<{ organization: Organization }>()
);

export const CoupleUserWithOrganization = createAction(
  '[Organizations] Couple user with organization',
  props<{
    organizationId: string;
    businessInfo: UserBusinessProfile;
  }>()
);

export const DecoupleUserFromOrganization = createAction(
  '[Organizations] Decouple user from organization',
  props<{ organizationId: string; mainOrganization: boolean }>()
);

export const DecoupleSuccessAndLogout = createAction(
  '[Organizations] Decoupled user successfully and logging out user'
);

export const DecoupleSuccessAndReload = createAction(
  '[Organizations] Decoupled user successfully and reload',
  props<{ organizationId: string; userId: string; portalPage: PortalPages }>()
);

export const OrganizationCoupledSuccessfully = createAction(
  '[Organizations] Organization coupled successfully',
  props<{ payload: unknown }>()
);

export const OrganizationCoupleError = createAction(
  '[Organizations] Organization couple error',
  props<{ error: ErrorMessage }>()
);

export const OrganizationDeleted = createAction(
  '[Organization] Organization deleted',
  props<{ id: string }>()
);

export const OrganizationAdded = createAction(
  '[Organizations] Organization added',
  props<{ id: string }>()
);
