import { Component } from '@angular/core';

// NGRX
import { select, Store } from '@ngrx/store';
import { portalQuery } from '../../../store/selectors/portal.selectors';
import { PortalState } from '../../../store/reducers/portal.reducer';

// RXJS
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DefaultUserGroups } from '../../../models/default-user-groups.interface';

// Model

@Component({
  selector: 'iam-user-name',
  templateUrl: './user-name.component.html',
  styleUrls: ['./user-name.component.scss'],
  providers: [],
})
export class UserNameComponent {
  private loaded$ = this.store.pipe(select(portalQuery.loadedPortal));
  private loggedIn$ = this.store.pipe(select(portalQuery.loggedInToPortal));

  portalInfo$ = this.store.pipe(
    select(portalQuery.getPortalInfo),
    filter(portalInfo => Boolean(portalInfo?.profile))
  );

  showLoader$ = combineLatest([this.loaded$, this.loggedIn$]).pipe(
    map((loaded, loggedIn) => !Boolean(loaded) && !Boolean(loggedIn))
  );

  userGroups = DefaultUserGroups;

  constructor(private store: Store<PortalState>) {}
}
