import { Injectable } from '@angular/core';

// RXJS
import { combineLatest, Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

// NGRX
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// NGRX router
import { getRouterSelectors } from '@ngrx/router-store';
// Models
import { Router } from '@angular/router';


import { convertRouteToPortalPage } from '../../core/utils/route-to-portal-page.utils';
import { OrganizationsService } from '../../core/services/organizations.service';
import { PortalInfoService } from '../../core/services/portal-info.service';
import { AppError } from '../actions/app.actions';
import { PortalInfoLoaded, SelectPortalOrganization, SelectPortalOrganizationSuccess, PortalInfoReload, PortalInfoLoad, PortalInfoError, ChangePortalLanguage } from '../actions/portal.actions';
import { portalQuery } from '../selectors/portal.selectors';
import { State } from '../reducers/state';

export const {
  selectUrl, // select the current url
} = getRouterSelectors();

// Services

@Injectable()
export class PortalEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<State>,
    private organizationsService: OrganizationsService,
    private portalInfoService: PortalInfoService
  ) {}

  // @ts-ignore
  userId$: Observable<string> = this.store.select(portalQuery.getUserId);

  getOrganizationRolesOnChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SelectPortalOrganization),
        filter(({ suppressBackendUpdate }) => !Boolean(suppressBackendUpdate)),
        switchMap(({ organizationId, portalPage }) =>
          combineLatest([
            this.organizationsService.selectOrganization(organizationId),
            this.userId$,
          ]).pipe(
            // @ts-ignore
            switchMap(([, userId]) => [
              SelectPortalOrganizationSuccess({
                organizationId,
              }),
              // @ts-ignore
              PortalInfoLoad({ userId, portalPage }),
            ])
          )
        )
      ),
    { dispatch: true }
  );

  organizationSelectedSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SelectPortalOrganization),
        filter(({ suppressBackendUpdate }) => Boolean(suppressBackendUpdate)),
        switchMap(({ organizationId }) => [
          SelectPortalOrganizationSuccess({
            organizationId,
          }),
        ])
      ),
    { dispatch: true }
  );

  PortalInfoReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortalInfoReload),
      withLatestFrom(
        this.store.select(portalQuery.getPortalInfo),
        this.store.select(selectUrl)
      ),
      switchMap(([, portalInfo, url]) =>
        of(
          PortalInfoLoad({
            // @ts-ignore
            userId: portalInfo.profile.sub,
            portalPage: convertRouteToPortalPage(url),
          })
        )
      )
    )
  );

  portalInfoLoad$ = createEffect(
    () =>
      this.actions$.pipe(
        // @ts-ignore
        ofType(PortalInfoLoad),
        switchMap(({ userId, portalPage }) =>
          this.portalInfoService.getPortalInfo(userId, portalPage).pipe(
            map(portalInfo => PortalInfoLoaded({ portalInfo, portalPage })),
            catchError(error => of(PortalInfoError({ error })))
          )
        )
      ),
    { dispatch: true }
  );

  portalInfoError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PortalInfoError),
        switchMap(error => [AppError({ error: error.error })])
      ),
    { dispatch: true }
  );

  changeLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChangePortalLanguage),
        switchMap(({ locale }) => this.portalInfoService.changeLanguage(locale))
      ),
    { dispatch: false }
  );
}
