import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { environment } from 'projects/iam/src/environments/environment';
import { RumService } from '../../features/organization/services/rum.service';
import {
  CancelChangeRequestsError,
  CreateNewChangeRequestsError,
  LoadChangeRequestsError,
  UpdateOrganizationRequestsError,
} from '../actions/change-requests.actions';
import { AppError } from '../actions/app.actions';
import { referenceDataLoadError } from '../actions/references.actions';
import {
  OrganizationCoupleError,
  OrganizationsLoadError,
} from '../actions/organizations.actions';
import { PortalInfoError } from '../actions/portal.actions';
import { loadRolesError } from '../actions/organization-roles.actions';

@Injectable()
export class RumEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private rumService: RumService
  ) {}

  recordPageView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        tap(() =>
          environment.environmentName !== 'mock'
            ? this.rumService.recordPageView({pageId: this.router.url})
            : ''
        )
      ),
    {
      dispatch: false,
    }
  );

  recordActionsErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AppError,
          CancelChangeRequestsError,
          LoadChangeRequestsError,
          CreateNewChangeRequestsError,
          UpdateOrganizationRequestsError,
          OrganizationsLoadError,
          OrganizationCoupleError,
          PortalInfoError,
          referenceDataLoadError,
          loadRolesError
        ),
        tap(({ error }) =>
          environment.environmentName !== 'mock'
            ? this.rumService.recordError(JSON.stringify(error))
            : ''
        )
      ),
    { dispatch: false }
  );
}
