import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MDModule } from './md.module';

import { ButtonModule } from 'primeng/button';

// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  initialState,
  SNACKBAR_FEATURE_KEY,
  SnackbarReducer,
} from './store/snackbar.reducer';
import { SnackbarEffects } from './store/snackbar.effects';

import { SNACKBAR_OPTIONS } from './snackbar-options.token';

import { SnackbarComponent } from './components/snackbar/snackbar.component';

import { SnackbarOptions } from './models/snackbar-options.model';
import { SnackbarService } from './services/snackbar.service';
import { ErrorSnackbarComponent } from './components/error-snackbar/error-snackbar.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CustomLoader } from 'projects/iam/src/app/core/translation/custom-loader';

@NgModule({
  imports: [
    CommonModule,
    MDModule,
    ButtonModule,
    StoreModule.forFeature(SNACKBAR_FEATURE_KEY, SnackbarReducer, {
      initialState: initialState,
    }),
    EffectsModule.forFeature([SnackbarEffects]),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        deps: [],
      },
    }),
  ],
  declarations: [SnackbarComponent, ErrorSnackbarComponent],
  providers: [SnackbarService],
})
export class PortbaseSnackbarModule {
  static forRoot(
    options: SnackbarOptions
  ): ModuleWithProviders<PortbaseSnackbarModule> {
    return {
      ngModule: PortbaseSnackbarModule,
      providers: [
        options.snackbarOptionsProvider || {
          provide: SNACKBAR_OPTIONS,
          useValue: options,
        },
      ],
    };
  }
}
