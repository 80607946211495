import { ShowSidebar, HideSidebar } from '../actions/sidebar.actions';
import { Action, createReducer, on } from '@ngrx/store';

export interface SidebarState {
    active: boolean;
}

export const initialState: SidebarState = {
    active: false,
}

const sidebarReducers = createReducer(
    initialState,
    on(ShowSidebar, (state) => ({
        ...state,
        active: true,
    })),
    on(HideSidebar, state => ({
        ...state,
        active: false,
    }))
)

export function reducer(
    state: SidebarState | undefined,
    action: Action
  ): SidebarState {
    return sidebarReducers(state, action);
  }
  