import { ErrorHandler, Injectable } from '@angular/core';
import { RumService } from '../../../features/organization/services/rum.service';

@Injectable({
  providedIn: 'root',
})
export class RumErrorHandler implements ErrorHandler {
  constructor(private rumService: RumService) {}

  handleError(error: unknown) {
    this.rumService.recordError(error);
  }
}
