import { createAction, props } from '@ngrx/store';
import { ReferenceOrganizationType } from '../../models/references/organization-type.interface';
import { ErrorMessage } from '../../models/error-message.interface';

export const loadRoles = createAction(
  '[organization-roles] Load organization types and roles',
  props<{ id: string }>()
);
export const loadRolesSuccess = createAction(
  '[organization-roles] Load organization types and roles success',
  props<{ roles: ReferenceOrganizationType[]; id: string }>()
);
export const loadRolesError = createAction(
  '[organization-roles] Load organization types and roles error',
  props<{ error: ErrorMessage }>()
);
