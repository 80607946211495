export const sharedTranslations = {
  organizationTypesDescription: {
    BARGE_OPERATOR: '',
    CARGADOOR_SHIPAGENT:
      'Der Schiffsmakler / Schiffsagent ist traditionell der Vertreter einer oder mehrerer Reedereien in einem Hafen. Die Aufgaben des Schiffsmaklers / Schiffsagenten konzentrieren sich einerseits auf Schiffs- und Besatzungsangelegenheiten und andererseits auf die Vertretung der Ladungsbeteiligten.',
    CUSTOMS: '',
    EMPTY_DEPOT: '',
    EXPORTER: '',
    FOOD_HEALTH_SERVICE: '',
    FORWARDER: '',
    IMPORTER: '',
    INSPECTION_STATION: '',
    IT_SERVICE_PROVIDER:
      'Ein IT-Service Provider bietet die Bereitstellung und Verwaltung von netzwerkbasierten Diensten und Anwendungen für Unternehmen',
    PORT_AUTHORITY: '',
    RAIL_HAULIER: '',
    RAIL_OPERATOR: '',
    RAILWAY_AUTHORITY: '',
    ROAD_HAULIER: '',
    SHIPPING_COMPANY: '',
    SKIPPER: '',
    TERMINAL: '',
  },
  organizationRolesDescription: {
    EMPLOYEE: 'Angestellter einer Organisation',
    FINANCIAL_DECLARANT:
      'Ein Finanzmelder ist für die Formalitäten und die Anmeldung eines oder mehrerer Hafenlogistikprozesse verantwortlich.',
    IAM_ADMIN: '',
    ID_CHECKER: '',
    OPERATOR_SHIPPING_CLERK:
      'Verantwortlich für die formellen Versand- und Frachtpapiere.',
    SCHEME_ADMIN: '',
    SD_EMPLOYEE: '',
    SD_STC: '',
  },
};
