import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IamRestService } from 'projects/iam/src/app/core/services/iam-rest.service';

// NGRX
import { Store } from '@ngrx/store';

// RXJS
import { Observable } from 'rxjs';
import { UserBusinessProfile, UserBusinessProfileBase } from '../../../models/user/user-business-profile.interface';
import { UserProfile } from '../../../models/user/userprofile.interface';

// Models

@Injectable({
  providedIn: 'root',
})
export class UsersService extends IamRestService {
  constructor(private _http: HttpClient, private _store: Store) {
    super(_http, _store);
  }

  // User profile
  getCurrentUser(): Observable<unknown> {
    const url = `${this.baseUrl}/users/:userId`;
    return this.get(url);
  }

  updateUser(payload: Partial<UserProfile>): Observable<unknown> {
    const url = `${this.baseUrl}/users/${payload.id}`;
    return this.put<unknown>(url, { ...payload });
  }

  identify(): Observable<unknown> {
    const url = `${this.baseUrl}/users/:userId/identification`;
    return this.put(url, {});
  }

  updateUserPassword(
    oldPassword: string,
    newPassword: string
  ): Observable<unknown> {
    const payload = {
      oldPassword: { value: oldPassword },
      newPassword: { value: newPassword },
    };

    const url = `${this.baseUrl}/users/:userId/credentials/change_password`;
    return this.put(url, payload);
  }

  // Business Information
  getBusinessInformation(): Observable<UserBusinessProfile> {
    const url = `${this.baseUrl}/users/:userId/businessinfo`;
    return this.get(url);
  }

  updateBusinessInformation(
    payload: UserBusinessProfileBase
  ): Observable<void> {
    const url = `${this.baseUrl}/users/:userId/businessinfo`;
    return this.put(url, payload);
  }

  // User deactivation
  deactivateUser(): Observable<unknown> {
    const url = `${this.baseUrl}/users/:userId/lifecycle/deactivate`;
    return this.put(url, {});
  }

  // reset MFA
  resetMFA(): Observable<unknown> {
    const url = `${this.baseUrl}/users/:userId/lifecycle/reset_factors`;
    return this.put(url, {});
  }
}
