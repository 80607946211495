import { Injectable } from '@angular/core';

declare const Beamer;

@Injectable({ providedIn: 'root' })
export class BeamerService {
  updateLanguage(language: string): void {
    if (typeof Beamer !== 'undefined') {
      Beamer.update({ language });
    }
    return;
  }

  updateUserId(userId: string): void {
    if (typeof Beamer !== 'undefined') {
      Beamer.update({ user_id: `iam_${userId}` });
    }
    return;
  }
}
